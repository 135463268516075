import gql from 'graphql-tag';
import { PART_TRACKER_FULL_FRAGMENT } from '../Fragment/PartTracker';
export const CREATE_PART_TRACKER = gql`
  mutation createPartTracker($createPartTracker: PartTrackerCreateInput!) {
    createPartTracker(partTracker: $createPartTracker) {
      ...FullPartTracker
    }
  }
  ${PART_TRACKER_FULL_FRAGMENT}
`;

export const UPDATE_PART_TRACKER = gql`
  mutation updatePartTracker($updatePartTracker: PartTrackerUpdateInput!) {
    updatePartTracker(partTracker: $updatePartTracker) {
      ...FullPartTracker
    }
  }
  ${PART_TRACKER_FULL_FRAGMENT}
`;

export interface DeliveryTypeUpdateInput {
  id: number;
  name: string;
  isShipping?: string;
}

export const UPDATE_DELIVERY_TYPE = gql`
  mutation updateDeliveryType($updateDeliveryType: DeliveryTypeUpdateInput!) {
    updateDeliveryType(deliveryType: $updateDeliveryType) {
      id
      name
      isShipping
    }
  }
`;

export const CREATE_DELIVERY_TYPE = gql`
  mutation createDeliveryType($createDeliveryType: DeliveryTypeCreateInput!) {
    createDeliveryType(deliveryType: $createDeliveryType) {
      id
      name
      isShipping
    }
  }
`;

export interface DeliveryTypeCreateInput {
  name: string;
  isShipping: string;
}
