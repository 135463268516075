import * as React from 'react';
import { Field } from 'formik';
import { FormGroup } from '@blueprintjs/core';

interface MyProps {
  label: string;
  name: string;
  disabled?: boolean;
}
const StringField = (props: MyProps) => (
  <FormGroup label={props.label}>
    <Field name={props.name} disabled={props.disabled} className="bp3-input bp3-fill" />
  </FormGroup>
);

export default StringField;
