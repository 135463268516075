import { FormGroup, Button } from '@blueprintjs/core';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  DeliveryTypeCreateInput,
  CREATE_DELIVERY_TYPE
} from '../../graphql/mutation/PartTracker';
import { DeliveryType } from '../../models/models';
import Loading from '../Loading';

const Sub = () => {
  const [createDeliveryType, { loading }] = useMutation<
    { createDeliveryType: DeliveryType },
    { createDeliveryType: DeliveryTypeCreateInput }
  >(CREATE_DELIVERY_TYPE);
  const initialValues: DeliveryTypeCreateInput = {
    name: '',
    isShipping: ''
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        createDeliveryType({ variables: { createDeliveryType: values } }).then(
          values => {
            if (values.errors) {
              console.log(values.errors);
              return;
            }
            if (
              values != null &&
              values.data != null &&
              values.data.createDeliveryType != null
            ) {
              window.location.replace(
                '/deliverytype/view/' + values.data.createDeliveryType.id
              );
            }
            setSubmitting(false);
          }
        );
      }}
      render={formikBag => (
        <Form style={{ margin: 30 }}>
          <h2>Create Delivery Type</h2>
          <div style={{ display: 'flex' }}>
            <div style={{ margin: 20 }}>
              <FormGroup label={'Name'}>
                <Field name="name" className="bp3-input bp3-fill" />
              </FormGroup>
              <Button
                type="submit"
                intent="success"
                large={true}
                style={{ float: 'right' }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      )}
    />
  );
};
export default Sub;
