import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { FormGroup, HTMLInputProps } from '@blueprintjs/core';

interface MyProps extends HTMLInputProps {
  name: string;
  label?: string;
}
const hasError = (props: FieldProps): boolean => {
  return (
    !!props.form.touched[props.field.name] &&
    !!props.form.errors[props.field.name]
  );
};
const innerReturn = (fieldProps: FieldProps, myProps: MyProps) => {
  let className = myProps.className || 'bp3-input bp3-fill';
  const { field, form } = fieldProps;
  let helperText;
  let formGroupClassName;
  if (hasError(fieldProps)) {
    className += ' bp3-intent-danger';
    formGroupClassName = 'bp3-intent-danger';
    helperText = form.errors[field.name];
  }
  return (
    <FormGroup
      label={myProps.label}
      helperText={helperText}
      className={formGroupClassName}
    >
      <input className={className} {...fieldProps.field} {...myProps} />
    </FormGroup>
  );
};
const InputField = (props: MyProps) => {
  return (
    <Field
      name={props.name}
      className="bp3-fill"
      style={{ paddingTop: 10 }}
      render={(fieldProps: FieldProps) => innerReturn(fieldProps, props)}
    />
  );
};

export default InputField;
