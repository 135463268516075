import gql from 'graphql-tag';
import { FULL_USER_FRAGMENT } from './User';

export const FULL_MESSAGE_FRAGMENT = gql`
  fragment FullMessage on Message {
    id
    createdAt
    body
    user {
      ...FullUser
    }
    userId
  }
  ${FULL_USER_FRAGMENT}
`;
