import { SurveyTemplate, SurveySection, SurveyFieldHasSurveySection, SurveyResponse, SurveyResponseHasSurveyField } from "../models/models";
import * as Survey from 'survey-react';

export const generateSurveyModel = (template: SurveyTemplate): Survey.SurveyModel => {
 let model = new Survey.ReactSurveyModel();
 model.showProgressBar = 'bottom';
 template?.surveySections.forEach(section =>  model.addPage(generateSurveySection(section)));
 return model;
}

export const generateSurveyModelResponse = (response: SurveyResponse): Survey.SurveyModel => {
    let model = generateSurveyModel(response.surveyTemplate);
    model.data = generateSurveyResponseData(response.surveyResponseHasSurveyField);
    model.mode = 'display';
    return model;
   }
const generateSurveyResponseData = (s: SurveyResponseHasSurveyField[]): any => {
    let data:any = {};
    s.forEach(v => data[v.surveyFieldHasSurveySectionId.toString()] = v.data)
    return data;
}
const generateSurveySection = (section: SurveySection): Survey.PageModel =>{
    let page = new Survey.PageModel(section.id.toString());
    page.title = section.title;
    section.surveyFields.slice().sort((a,b) => a.fieldNumber - b.fieldNumber).forEach(field => page.addQuestion(generateQuestion(field)))
    return page;
}
const generateQuestion = (field: SurveyFieldHasSurveySection): Survey.Question => {
    switch (field.surveyField.surveyDataType.name) {
        case "Radio 6":
            return generateRatingQuestion(field);
        case "Short String":
        case "Long String":
        default:
            return generateShortString(field);
    }
}
const generateRatingQuestion = (field:SurveyFieldHasSurveySection ): Survey.QuestionRatingModel => {
    let question = new Survey.QuestionRatingModel(field.surveyField.id.toString());
    question.title = field.surveyField.title;
    question.minRateDescription = "Not Satisfied";
    question.maxRateDescription = "Completely Satisfied";
    return question;
}
const generateShortString = (field:SurveyFieldHasSurveySection): Survey.QuestionCommentModel => {
    let question = new Survey.QuestionCommentModel(field.surveyField.id.toString());
    question.title = field.surveyField.title;
    return question;
}