import gql from 'graphql-tag';
import { FULL_USER_FRAGMENT } from './User';

export const PART_TRACKER_FULL_FRAGMENT = gql`
  fragment FullPartTracker on PartTracker {
    id
    jobNumber
    lineNumber
    partName
    recNumber
    poNumber
    poDate
    poRecievedDate
    assemblyTeam {
      id
      name
    }
    sasPartNumber
    manPartNumber
    quantity
    vendor
    createdDate
    expectedDate
    ecoNumber
    reason
    department {
      id
      name
    }
    status {
      id
      name
    }
    deliveryType {
      id
      name
      isShipping
    }
    deliveryNotes
    reasonNotes
    trackingNumber
    partsTrackerStatusId
    assemblyTeamId
    deliveryTypeId
    departmentId
    deliveryDate
    projectManagerId
    projectManager {
      ...FullUser
    }
    designRepId
    designRep {
      ...FullUser
    }
    lastModifiedByUser {
      ...FullUser
    }
    createdByUser {
      ...FullUser
    }
    ecoNotes
    requestedDate
  }
  ${FULL_USER_FRAGMENT}
`;
