import { FormikProps } from 'formik';
import { Button } from '@blueprintjs/core';
import * as React from 'react';

const UpdateSaveButton = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikBag: FormikProps<any>;
  loading: boolean;
}) => (
  <Button
    hidden={!props.formikBag.dirty}
    type="submit"
    intent="success"
    large={true}
    style={{ float: 'right' }}
    loading={props.loading}
  >
    Save
  </Button>
);

export default UpdateSaveButton;
