
import { Form, Formik } from 'formik';
import * as React from 'react';
import NotesField from '../components/Form/Field/NotesField';
import StringField from '../components/Form/Field/StringField';
import Column from '../components/Layout/Column';
import UpdateSaveButton from '../components/Form/UpdateSaveButton';
import { SurveyResponseCreateInput } from '../models/models';
import {  SurveyResponseCreateMutation } from '../graphql/mutation/survey';
import { Redirect } from 'react-router';

const CreateSurveyResponseForm = (props: { templateId: number; }) => {
  const [createSurveyResponse, { loading, data }] = SurveyResponseCreateMutation();
  const surveyResponseCreateInput: SurveyResponseCreateInput = {
    surveyTemplateId: props.templateId,
    hiddenNotes: "",
    companyName: "",
    title: ""
  };
  if (!loading && data && data.createSurveyResponse && data.createSurveyResponse.uri){
    return (<Redirect to={'/view/surveyresponse/'+data.createSurveyResponse.id}/>)
  }
  return (
    <Formik
      initialValues={surveyResponseCreateInput}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        const surveyResponseCreateInput: SurveyResponseCreateInput = {
          surveyTemplateId: props.templateId,
          hiddenNotes: values.hiddenNotes,
          companyName: values.companyName,
          title: values.title
        };
        createSurveyResponse({ variables: { surveyResponseCreateInput } }).then(
          values => {
            if (values && values.errors) {
              return;
            }
            if (
              values != null &&
              values.data != null &&
              values.data.createSurveyResponse != null
            ) {
            }
            setSubmitting(false);
          }
        );
      }}
      render={formikBag => (
        <Form style={{ margin: 30 }}>
          <h2>Create Survey Response Link</h2>
          <Column>
            <StringField label="Title" name="title" />
            <StringField label="Company Name" name="companyName" />
            <NotesField label="Hidden Notes" name="hiddenNotes" />
            <UpdateSaveButton formikBag={formikBag} loading={loading} />
          </Column>
        </Form>
      )}
    />
  );
};
export default CreateSurveyResponseForm;
