import gql from 'graphql-tag';

export const ASSEMBLY_TEAMS_QUERY = gql`
  query assemblyTeams {
    assemblyTeams {
      id
      name
    }
  }
`;
