import { Button } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import { PartTrackerCreateInput, PartTracker } from '../../../models/models';
import * as React from 'react';
import { CREATE_PART_TRACKER } from '../../../graphql/mutation/PartTracker';
import { useMutation } from '@apollo/react-hooks';
import UserSelectWithUserTitleField from '../../Form/Field/UserSelectWithUserTitleField';
import DateField from '../../Form/Field/DateField';
import DepartmentSelectField from '../../Form/Field/DepartmentSelectField';
import AssemblyTeamField from '../../Form/Field/AssembalyTeamField';
import NotesField from '../../Form/Field/NotesField';
import NumberField from '../../Form/Field/NumberField';
import StringField from '../../Form/Field/StringField';
import Column from '../../Layout/Column';
import ColumnContainer from '../../Layout/ColumnContainer';
import Loading from '../../Loading';
import ReasonSelectField from '../../Form/Field/ReasonSelectField';

const Sub = () => {
  const [createPartTracker, { loading }] = useMutation<
    { createPartTracker: PartTracker },
    { createPartTracker: PartTrackerCreateInput }
  >(CREATE_PART_TRACKER);
  const initialValues: PartTrackerCreateInput = {
    jobNumber: '',
    lineNumber: '',
    partName: '',
    manPartNumber: '',
    quantity: 1,
    sasPartNumber: '',
    vendor: '',
    assemblyTeamId: 1,
    departmentId: 1,
    ecoNumber: '',
    ecoNotes: '',
    reason:'',
    requestedDate: new Date(),
    designRepId: null,
    projectManagerId: null,
    reasonNotes: ''
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        setSubmitting(true);
        createPartTracker({ variables: { createPartTracker: values } }).then(
          values => {
            if (values.errors) {
              console.log(values.errors);
              return;
            }
            if (
              values != null &&
              values.data != null &&
              values.data.createPartTracker != null
            ) {
              window.location.replace(
                '/parttracker/view/' + values.data.createPartTracker.id
              );
            }
            setSubmitting(false);
          }
        );
      }}
      render={() => (
        <Form style={{ margin: 30 }}>
          <h2>Create Part Request</h2>
          <ColumnContainer>
            <Column>
              <StringField label="Job Number" name="jobNumber" />
              <StringField label="Line Number" name="lineNumber" />
              <StringField label="Part Name" name="partName" />
              <ReasonSelectField label="Reason" name="reason" />
              <NumberField label="Quantity" name="quantity" />
              <StringField label="SAS Part #" name="sasPartNumber" />
              <StringField label="ECO Number" name="ecoNumber" />
              <NotesField label="ECO Notes" name="ecoNotes" />
            </Column>
            <Column>
              <DateField name="requestedDate" label="Requested Date" />
              <DepartmentSelectField name="departmentId" label="Department" />
              <AssemblyTeamField label="Assembly Team" name="assemblyTeamId" />
              <UserSelectWithUserTitleField
                label="Project Manager"
                name="projectManagerId"
                userTitle="Project Manager"
              />
              <UserSelectWithUserTitleField
                label="Design Rep"
                name="designRepId"
                userTitle="Lead Engineer"
              />
              <NotesField label="Reason Notes" name="reasonNotes" />
              <Button
                type="submit"
                intent="success"
                large={true}
                style={{ float: 'right' }}
              >
                Submit
              </Button>
            </Column>
          </ColumnContainer>
        </Form>
      )}
    />
  );
};
export default Sub;
