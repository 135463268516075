import * as React from 'react';
import { FieldProps, Field } from 'formik';
import DepartmentSelect from '../Select/DepartmentSelect';
import { FormGroup } from '@blueprintjs/core';

interface MyProps {
  label: string;
  name: string;
}

const DepartmentSelectField = (props: MyProps) => (
  <FormGroup label={props.label} className="bp3-fill">
    <Field
      name={props.name}
      render={({ field, form }: FieldProps) => {
        return (
          <DepartmentSelect
            selectedId={field.value}
            onItemSelect={(item, e) => form.setFieldValue(field.name, item.id)}
          />
        );
      }}
    />
  </FormGroup>
);

export default DepartmentSelectField;
