import { ColGroupDef } from 'ag-grid-community';
import { intCol, strCol, dateCol } from './ColDef';

export const getSurveyResponse = (str: string): ColGroupDef => {
  return {
    headerName: 'Survey Response',
    children: [
      {
        headerName: 'id',
        cellRenderer: params => {
          if (!params.value) {
            return '';
          }
          return `<a href="/view/surveyresponse/${params.value}"  rel="noopener" target="_blank"}>${params.value}</a>`;
        },
        field: str + 'id',
        ...intCol
      },
      {
        headerName: 'Company Name',
        field: str + 'companyName',
        ...strCol
      },
      {
        headerName: 'Title',
        field: str + 'title',
        ...strCol
      },
      {
        headerName: 'Created At',
        field: str + 'createdAt',
        ...dateCol
      },
      {
        headerName: 'Responded Date',
        field: str + 'respondedDate',
        ...dateCol
      },
      {
        headerName: 'Created By Email',
        field: str + 'createdBy.email',
        ...strCol
      },
    ]
  };
};
