import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { TextArea, FormGroup } from '@blueprintjs/core';

interface MyProps {
  label: string;
  name: string;
}
const NotesField = (props: MyProps) => (
  <FormGroup label={props.label} className="bp3-fill">
    <Field
      name={props.name}
      className="bp3-input bp3-fill"
      render={({ field, form }: FieldProps) => {
        return (
          <TextArea
            {...field}
            value={field.value || ''}
            style={{ minHeight: 100 }}
            className="bp3-fill"
          />
        );
      }}
    />
  </FormGroup>
);
export default NotesField;
