
import { Form, Formik } from 'formik';
import * as React from 'react';
import NotesField from '../components/Form/Field/NotesField';
import StringField from '../components/Form/Field/StringField';
import Column from '../components/Layout/Column';
import UpdateSaveButton from '../components/Form/UpdateSaveButton';
import ErrorComponent from '../components/ErrorComponent';
import Loading from '../components/Loading';
import { SurveyResponseUpdateInput } from '../models/models';
import { SurveyResponseResponse } from '../graphql/query/survey';
import { SurveyResponseUpdateMutation } from '../graphql/mutation/survey';
import { ApolloCurrentQueryResult } from 'apollo-client';
import { FormGroup } from '@blueprintjs/core';

const ViewSurveyResponseAdditionalForm = (props: { result: ApolloCurrentQueryResult<SurveyResponseResponse> }) => {
  const { result } = props;
  if (result.loading) {
    return <Loading />;
  }
  if (result.errors) {
    return <ErrorComponent />;
  }
  const [updateSurveyResponse, { loading }] = SurveyResponseUpdateMutation();
  return (
    <Formik
      initialValues={result.data!.surveyResponse!}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        const surveyResponseUpdateInput: SurveyResponseUpdateInput = {
          id: values.id,
          hiddenNotes: values.hiddenNotes,
          companyName: values.companyName,
          title: values.title
        };
        updateSurveyResponse({ variables: { surveyResponseUpdateInput } }).then(
          values => {
            if (values && values.errors) {
              return;
            }
            if (
              values != null &&
              values.data != null &&
              values.data.updateSurveyResponse != null
            ) {
            }
            setSubmitting(false);
          }
        );
      }}
      render={formikBag => (
        <Form style={{ margin: 30 }}>
          <h2>View/Edit Additional Info</h2>
          <Column>
            <StringField label="Title" name="title" />
            <StringField label="Company Name" name="companyName" />
            <NotesField label="Hidden Notes" name="hiddenNotes" />
            <FormGroup  label="Response Link" className="bp3-fill">
              <p>{ "https://" + window.location.host + "/respond/survey/" + result.data!.surveyResponse!.uri }</p>
            </FormGroup>
            <FormGroup  label="Created By Email" className="bp3-fill">
              <p>{result.data!.surveyResponse!.createdBy.email}</p>
            </FormGroup>
            <FormGroup  label="Created At" className="bp3-fill">
              <p>{result.data!.surveyResponse!.createdAt}</p>
            </FormGroup>
            <FormGroup  label="Responded Date" className="bp3-fill">
              <p>{result.data!.surveyResponse!.respondedDate}</p>
            </FormGroup>
            <FormGroup  label="Has Responded" className="bp3-fill">
              <p>{result.data!.surveyResponse!.hasResponded}</p>
            </FormGroup>
            <UpdateSaveButton formikBag={formikBag} loading={loading} />
          </Column>
        </Form>
      )}
    />
  );
};
export default ViewSurveyResponseAdditionalForm;
