import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ME } from '../../graphql/query/me';
import { Users } from '../../models/models';
import { Redirect } from 'react-router';
import Loading from '../Loading';


const RequireUser = (props: { children?: React.ReactNode; }) => {
  let user: Users | undefined = undefined;
  const u = localStorage.getItem('user');
  if (u) {
    user = JSON.parse(u);
  }
    const { data, loading, error } = useQuery(ME, {
      fetchPolicy: 'cache-and-network',skip: !localStorage.getItem('token'),
    });
    if (error) {
      return <Redirect to='/login' />;
    }
    if (!loading) {
      if (data && data.me) {
        localStorage.setItem('user', JSON.stringify(data.me));
        user = data.me;
      }
    }
    if (loading) {
      return (
          <Loading />
       );
    }
  if (!!user) {
    return (<>{props.children}</>);
  }
  return <Redirect to='/login' />;
}
export default RequireUser;