import * as React from 'react';
import { logout } from '..';
import { Redirect } from 'react-router';
import Loading from '../components/Loading';

const Logout = () => {
  const [redirect, setRedirect] = React.useState<boolean>(false);
  logout().then(() => setRedirect(true));
  if (redirect) {
    return <Redirect to={'/'} />;
  }
  return <Loading />;
};

export default Logout;
