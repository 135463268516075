import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import 'normalize.css/normalize.css';

import './custom.css';
import { Login } from './forms/Login';
import JobsTable from './components/Jobs/View/JobsTable';
import { useQuery } from '@apollo/react-hooks';
import { ME } from './graphql/query/me';
import { Users } from './models/models';
import CreatePartTrackerForm from './components/PartTracker/Create/CreatePartTrackerForm';
import ViewPartTrackerForm from './components/PartTracker/View/ViewPartTrackerForm';
import ViewPartTrackerTable from './components/PartTracker/View/ViewPartTrackerTable';
import CreateDeliveryTypeForm from './components/DeliveryType/CreateDeliveryTypeForm';
import UpdateDeliveryTypeForm from './components/DeliveryType/UpdateDeliveryTypeForm';
import ViewDeliveryTypeTable from './components/DeliveryType/ViewDeliveryTypeTable';
import CreateInformationRequestForm from './components/InformationRequest/Create/CreateInformationRequestForm';
import ViewInformationRequestView from './components/InformationRequest/View/ViewInformationRequestView';
import ViewInformationRequestTable from './components/InformationRequest/View/ViewInformationRequestTable';
import PasswordResetResponse from './components/PasswordReset/PasswordResetResponse';
import ResetLink from './components/PasswordReset/ResetLink';
import { PasswordReset } from './components/PasswordReset/PasswordReset';
import ResetLinkSuccess from './components/PasswordReset/ResetLinkSuccess';
import { logout } from '.';
import AdminSignupUser from './components/User/AdminSignupUser';
import UpdateUser from './components/User/AdminUpdateUser';
import Logout from './forms/Logout';
import ReactGA from 'react-ga';
import { RequireLoginRoute } from './components/RequireLoginRoute';
import { ViewSurveyTemplate } from './survey/ViewSurveyTemplate';
import { RespondSurveyForm } from './survey/RespondSurveyForm';
import Loading from './components/Loading';
import { SurveyForm } from './survey/SurveyForm';
import ListSurveyTemplates from './survey/ListSurveyTemplates';
import SurveyResponsesTable from './survey/SurveyResponesTable';
import { ViewSurveyResponse } from './survey/ViewSurveyResponse';
import UpdateDepartmentForm from './components/Department/UpdateDepartmentForm';
import ViewDepartmentsTable from './components/Department/ViewDepartmentsTable';

export default () => {
  const [initalPageView, setInitalPageView] = React.useState<boolean>(true);

  if (initalPageView) {
    ReactGA.initialize('UA-154890799-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    setInitalPageView(false);
  }

  let user: Users | undefined = undefined;
  const u = localStorage.getItem('user');
  if (u) {
    user = JSON.parse(u);
  }
  const query = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
    skip: !localStorage.getItem('token')
  });
  const { data, loading, error } = query;
  if (!loading) {
    if (data && data.me) {
      localStorage.setItem('user', JSON.stringify(data.me));
      ReactGA.set({ userId: data.me.id });
      user = data.me;
    } 
    if (error){
      logout();
    }
  }
  if (loading) {
    return (
      <Layout user={user}>
        <Loading />
      </Layout>
    );
  }

  return (
      <Switch>
        <Route path="/respond/survey">
          <Layout user={user} isExternal={true}>
            <Route path="/respond/survey/:uri" component={RespondSurveyForm} />
          </Layout>
        </Route>
        <Layout user={user}>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <RequireLoginRoute path="/view/survey/:id" component={ViewSurveyTemplate} />
          <RequireLoginRoute path="/view/survey" exact component={ListSurveyTemplates} />
          <RequireLoginRoute path="/view/surveyresponse" exact component={SurveyResponsesTable} />
          <RequireLoginRoute path="/view/surveyresponse/:id" exact component={ViewSurveyResponse} />
          <RequireLoginRoute path="/create/survey" exact component={SurveyForm} />
          <RequireLoginRoute path="/create/survey/:id" exact component={ViewSurveyTemplate} />
          <Route exact={true} path="/PasswordReset" component={PasswordReset} />
          <Route
            exact={true}
            path="/PasswordReset/Response"
            component={PasswordResetResponse}
          />
          <Route exact={true} path="/PasswordReset/success" component={ResetLinkSuccess} />
          <Route path="/reset/:secret" component={ResetLink} />
          <RequireLoginRoute path="/jobs" component={JobsTable} />
          <RequireLoginRoute path="/parttracker/create" component={CreatePartTrackerForm} />
          <RequireLoginRoute path="/parttracker/view/:id" component={ViewPartTrackerForm} />
          <RequireLoginRoute
            exact={true}
            path="/parttracker"
            component={ViewPartTrackerTable}
          />
          <RequireLoginRoute
            exact={true}
            path="/deliverytype/create"
            component={CreateDeliveryTypeForm}
          />
          <RequireLoginRoute path="/deliverytype/view/:id" component={UpdateDeliveryTypeForm} />
          <RequireLoginRoute
            path="/InformationRequest/view/:id"
            component={ViewInformationRequestView}
          />
          <RequireLoginRoute
            exact={true}
            path="/InformationRequest"
            component={ViewInformationRequestTable}
          />
          <RequireLoginRoute
            exact={true}
            path="/InformationRequest/create"
            component={CreateInformationRequestForm}
          />
          <RequireLoginRoute
            exact={true}
            path="/deliverytype"
            component={ViewDeliveryTypeTable}
          />
          <RequireLoginRoute path="/user/view/:id" component={UpdateUser} />
          <RequireLoginRoute path="/user/signup" component={AdminSignupUser} />
          <RequireLoginRoute path="/view/department/:id" component={UpdateDepartmentForm} />
          <RequireLoginRoute path="/view/departments" component={ViewDepartmentsTable} />
        </Layout>
      </Switch>
  );
};
