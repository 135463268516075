import { Button } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as React from 'react';
import NotesField from '../Form/Field/NotesField';
import { AdminUpdateUser } from '../../graphql/mutation/User';
import { UserQuery } from '../../graphql/query/user';
import { AdminUpdateUserInput } from '../../models/models';
import StringField from '../Form/Field/StringField';
import UserTitleField from '../Form/Field/UserTitleField';
import ErrorComponent from '../ErrorComponent';
import Loading from '../Loading';

const UpdateUser = (props: RouteComponentProps<{ id: string }>) => {
  const { id } = props.match.params;
  const { loading: loading2, error, data } = UserQuery({ id });
  const [updateUser, { loading }] = AdminUpdateUser();
  if (loading2 || loading) {
    return <Loading />;
  }
  if (error || data?.user?.id === undefined) {
    return  <ErrorComponent />;
  }

  return (
    <Formik
      initialValues={data.user}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        const update: AdminUpdateUserInput = {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: data.user!.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          notes: values.notes,
          deleted: values.deleted,
          userTitleId: values.userTitleId
        };
        updateUser({ variables: { user: update } }).then(() => {
          setSubmitting(false);
        });
      }}
      render={() => (
        <Form style={{ margin: 30 }}>
          <h2>View/Edit User - Admin</h2>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div style={{ margin: 20 }}>
              <StringField name="email" label={'Email'} />
              <StringField name="firstName" label={'First Name'} />
              <StringField name="lastName" label={'Last Name'} />
              <NotesField name="notes" label={'Notes'} />
              <UserTitleField name="userTitleId" label={'User Title'} />
              <Button
                type="submit"
                intent="success"
                large={true}
                style={{ float: 'right' }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      )}
    />
  );
};
export default UpdateUser;
