import * as Survey from "survey-react";
import "survey-react/survey.css"
import * as React from "react";
import { generateSurveyModelResponse } from "./processSurvey";
import { SurveyResponseQuery } from "../graphql/query/survey";
import { Card } from "@blueprintjs/core";
import { RouteComponentProps } from "react-router";
import Loading from "../components/Loading";
import ViewSurveyResponseAdditonalForm  from "./ViewSurveyResponseAdditonalForm";
const onComplete = (survey: any, options: any) => {
    //Write survey results into database
    console.log("Survey results: " + JSON.stringify(survey.data));
   }
export const ViewSurveyResponse = (props: RouteComponentProps<{ id: string }>) => {
    const { id } = props.match.params;
    const response = SurveyResponseQuery({ id });
    const { loading, error, data }  = response;
    if (loading) {
        return <Loading />;
    }
    if (!data) {
        return <p>dataNotFound</p>;
    }
    if (error) {
        return <p>{error.message}</p>;
    }

    return (
    <div>
        <Card>
            <ViewSurveyResponseAdditonalForm  result={response}/>
        </Card>
        <Card>
            <h2>View Survey</h2>
            <Survey.Survey model={generateSurveyModelResponse(data.surveyResponse!)} onComplete={onComplete}  />
        </Card>
        </div>);
}