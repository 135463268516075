import * as React from 'react';

import { useQuery } from '@apollo/react-hooks';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { ColGroupDef } from 'ag-grid-community';

import 'ag-grid-enterprise';
import { DELIVERY_TYPES_QUERY } from '../../graphql/query/PartTracker';
import { DeliveryType } from '../../models/models';
import { standardSideBar, genDeliveryType } from '../../Table/ColGroupDef';
import Loading from '../Loading';
import ErrorComponent from '../ErrorComponent';

interface DeliveryTypeArray {
  deliveryTypes: DeliveryType[];
}

function App() {
  const colGroup: ColGroupDef[] = [genDeliveryType('')];

  const { loading, error, data } = useQuery<DeliveryTypeArray>(
    DELIVERY_TYPES_QUERY
  );
  if (loading) {
    return <Loading />;
  }
  if (!data) {
    return <ErrorComponent />;
  }
  if (error) {
    return <ErrorComponent errorText={error.message} />;
  }

  return (
    <div style={{ height: 'calc(100vh - 50px)' }} className="ag-theme-balham">
      <AgGridReact
        columnDefs={colGroup}
        enableRangeSelection={true}
        sideBar={standardSideBar}
        enableCharts={true}
        rowData={data.deliveryTypes}
      ></AgGridReact>
    </div>
  );
}

export default App;
