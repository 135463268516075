import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { NumericInput, FormGroup } from '@blueprintjs/core';

const NumInputQuant = ({ field, form }: FieldProps) => {
  return (
    <NumericInput
      {...field}
      onValueChange={value => form.setFieldValue(field.name, value)}
    />
  );
};
interface MyProps {
  label: string;
  name: string;
}
const NumberField = (props: MyProps) => (
  <FormGroup label={props.label}>
    <Field name={props.name} render={NumInputQuant} />
  </FormGroup>
);

export default NumberField;
