import gql from 'graphql-tag';
import { FULL_USER_FRAGMENT } from '../Fragment/User';
export const ME = gql`
  query me {
    me {
      ...FullUser
    }
  }
  ${FULL_USER_FRAGMENT}
`;
