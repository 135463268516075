import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import {
  SignupUserInput,
  Users,
  AdminUpdateUserInput
} from '../../models/models';
import { FULL_USER_FRAGMENT } from '../Fragment/User';

export interface SignupUserArgs {
  user: SignupUserInput;
}

export interface SignupUserResponse {
  signupUser?: Users;
}
export const SIGNUP_USER = gql`
  mutation signupUser($user: SignupUserInput!) {
    signupUser(user: $user) {
      ...FullUser
    }
  }
  ${FULL_USER_FRAGMENT}
`;

export const SignupUser = () => {
  return useMutation<SignupUserResponse, SignupUserArgs>(SIGNUP_USER, {
    onError: () => {}
  });
};

export interface SignupUserResendEmailArgs {
  email: string;
}

export interface SignupUserResendEmailResponse {
  signupUserResendEmail?: Users;
}
export const SIGNUP_USER_RESEND_EMAIL = gql`
  mutation signupUserResendEmail($email: String!) {
    signupUserResendEmail(email: $email)
  }
`;

export const SignupUserResendEmail = () => {
  return useMutation<SignupUserResendEmailResponse, SignupUserResendEmailArgs>(
    SIGNUP_USER_RESEND_EMAIL,
    { onError: () => {} }
  );
};

export interface AdminUpdateUserArgs {
  user: AdminUpdateUserInput;
}

export interface AdminUpdateUserResponse {
  adminUpdateUser?: Users;
}
export const ADMIN_UPDATE_USER = gql`
  mutation adminUpdateUser($user: AdminUpdateUserInput!) {
    adminUpdateUser(user: $user) {
      ...FullUser
    }
  }
  ${FULL_USER_FRAGMENT}
`;

export const AdminUpdateUser = () => {
  return useMutation<AdminUpdateUserResponse, AdminUpdateUserArgs>(
    ADMIN_UPDATE_USER,
    { onError: () => {} }
  );
};
