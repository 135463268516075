import { Select, ItemRenderer } from '@blueprintjs/select';
import { AssemblyTeams } from '../../models/models';
import { ASSEMBLY_TEAMS_QUERY } from '../../graphql/query/AssemblyTeam';
import { useQuery } from '@apollo/react-hooks';
import { Button, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
interface AssemblyTeamsArray {
  assemblyTeams: AssemblyTeams[];
}

const AssemblyTeamSelect = Select.ofType<AssemblyTeams>();

const itemRenderer: ItemRenderer<AssemblyTeams> = (
  { id, name },
  { handleClick, index }
) => (
  <MenuItem
    key={id}
    onClick={handleClick}
    tabIndex={index}
    style={{
      fontSize: 16,
      fontWeight: 'bolder',
      margin: 2,
      textAlign: 'center'
    }}
    text={name}
  />
);
interface MyProps {
  selectedId: number;
  onItemSelect: (
    item: AssemblyTeams,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void;
}

const AssemblyTeamSugestion = (props: MyProps) => {
  const { loading, error, data } = useQuery<AssemblyTeamsArray>(
    ASSEMBLY_TEAMS_QUERY
  );
  if (loading) {
    return <p>loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data || !data.assemblyTeams) {
    return <p>is null</p>;
  }
  const find = data.assemblyTeams.find(a => a.id === props.selectedId);
  let text = 'NONE';
  if (find) {
    text = find.name;
  }
  return (
    <AssemblyTeamSelect
      items={data.assemblyTeams}
      itemRenderer={itemRenderer}
      onItemSelect={props.onItemSelect}
      noResults={<MenuItem disabled={true} text="No results." />}
    >
      <Button fill={true} text={text} rightIcon="double-caret-vertical" />
    </AssemblyTeamSelect>
  );
};

export default AssemblyTeamSugestion;
