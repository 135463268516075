import gql from 'graphql-tag';

export const DEPARTMENTS_QUERY = gql`
  query departments {
    departments {
      id
      name
    }
  }
`;
