import gql from 'graphql-tag';
import { ProjectType } from '../../models/models';
import { useQuery } from '@apollo/react-hooks';

interface ProjectTypesQueryResult {
  projectTypes: ProjectType[];
}

export const PROJECT_TYPES_QUERY = gql`
  query ProjectType {
    projectTypes {
      id
      name
    }
  }
`;

export const ProjectTypesQuery = () =>
  useQuery<ProjectTypesQueryResult>(PROJECT_TYPES_QUERY);
