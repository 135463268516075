import { Users } from '../../../../models/models';
import { useQuery } from '@apollo/react-hooks';
import { Button, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
import {
  USERS_TITLE_QUERY,
  UsersTitleQueryArgs
} from '../../../../graphql/query/user';
import {
  formatUser,
  UsersArray,
  userItemRenderer,
  UserSelect,
  filterUser
} from './UserSelectRender';

interface MyProps {
  selectedId: number;
  onItemSelect: (
    item: Users,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void;
  args: UsersTitleQueryArgs;
}

export default (props: MyProps) => {
  const { loading, error, data } = useQuery<UsersArray, UsersTitleQueryArgs>(
    USERS_TITLE_QUERY,
    { variables: props.args }
  );
  if (loading) {
    return <p>loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  if (data == null || data.users == null) {
    return <p>is null</p>;
  }
  const find = data.users.find(a => a.id === props.selectedId);
  let text = 'NONE';
  if (find) {
    text = formatUser(find);
  }
  return (
    <UserSelect
      items={data.users}
      itemPredicate={filterUser}
      itemRenderer={userItemRenderer}
      onItemSelect={props.onItemSelect}
      noResults={<MenuItem disabled={true} text="No results." />}
    >
      <Button text={text} rightIcon="double-caret-vertical" />
    </UserSelect>
  );
};
