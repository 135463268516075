import * as React from 'react';
import { Card, Elevation } from '@blueprintjs/core';

const PasswordResetResponse = () => (
  <Card elevation={Elevation.TWO} style={{ minWidth: '25%' }}>
    <h2>Password Reset Link has been sent</h2>
    <p>Make sure to check the spam folder as well.</p>
  </Card>
);
export default PasswordResetResponse;
