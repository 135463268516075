import gql from 'graphql-tag';
import { FULL_USER_FRAGMENT } from '../Fragment/User';
import { UserTitles, Users } from '../../models/models';
import { useQuery } from '@apollo/react-hooks';

export interface UsersTitleQueryArgs {
  userTitle: string;
}

export const USERS_TITLE_QUERY = gql`
  query UserTitle($userTitle: String) {
    users(where: [{ path: "userTitle.name", value: [$userTitle] }]) {
      ...FullUser
    }
  }
  ${FULL_USER_FRAGMENT}
`;

export const USERS_QUERY = gql`
  query UserTitleQuery {
    users {
      ...FullUser
    }
  }
  ${FULL_USER_FRAGMENT}
`;

interface UserTitleAllQueryResults {
  userTitles: UserTitles[];
}
export const USER_TITLE_ALL_QUERY = gql`
  query UserTitleAll {
    userTitles {
      id
      name
    }
  }
`;

export const UserTitleAllQuery = () =>
  useQuery<UserTitleAllQueryResults>(USER_TITLE_ALL_QUERY);

export interface UserQueryArgs {
  id: string;
}
export interface UserQueryResponse {
  user?: Users;
}

export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...FullUser
    }
  }
  ${FULL_USER_FRAGMENT}
`;

export const UserQuery = (args: UserQueryArgs) =>
  useQuery<UserQueryResponse, UserQueryArgs>(USER_QUERY, { variables: args });
