import { Button } from '@blueprintjs/core';
import { Form, Formik, FormikProps, FormikHelpers } from 'formik';
import * as React from 'react';
import {
  CreateInformationRequestArgs,
  CreateInformationRequestMutation
} from '../../../graphql/mutation/InformationRequest';
import DateField from '../../Form/Field/DateField';
import DepartmentSelectField from '../../Form/Field/DepartmentSelectField';
import NotesField from '../../Form/Field/NotesField';
import ProjectTypeField from '../../Form/Field/ProjectTypeField';
import StringField from '../../Form/Field/StringField';
import UserSelectField from '../../Form/Field/UserSelectField';
import RequestLevelSelectField from '../../Form/Field/RequestLevelField';
import Loading from '../../Loading';

const initialValues: CreateInformationRequestArgs = {
  informationRequest: {
    modelNumber: '',
    projectNumber: '',
    title: '',
    requestedDate: new Date(),
    assignedUserId: null,
    departmentId: null,
    informationRequestStatusId: null,
    projectTypeId: null,
    requestLevelId: null,
    requestingUserId: null
  },
  message: {
    body: ''
  }
};

const CreateInformationRequestForm = () => {
  const [
    createInformationRequest,
    { loading }
  ] = CreateInformationRequestMutation();

  const submitForm = (
    variables: CreateInformationRequestArgs,
    { setSubmitting }: FormikHelpers<CreateInformationRequestArgs>
  ) => {
    setSubmitting(true);
    createInformationRequest({ variables }).then(values => {
      if (values.errors) {
        console.log(values.errors);
        return;
      }
      if (
        values != null &&
        values.data != null &&
        values.data.createInformationRequest != null
      ) {
        window.location.replace(
          '/InformationRequest/view/' + values.data.createInformationRequest.id
        );
      }
      setSubmitting(false);
    });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      render={RenderForm}
    />
  );
};

const RenderForm = (formikBag: FormikProps<CreateInformationRequestArgs>) => (
  <Form style={{ margin: 30 }}>
    <h2>Create Information Request</h2>
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <div style={{ margin: 20 }}>
        <StringField
          name="informationRequest.modelNumber"
          label={'Model Number'}
        />
        <StringField
          name="informationRequest.projectNumber"
          label={'Project Number'}
        />
        <DateField
          name="informationRequest.requestedDate"
          label="Requested Date"
        />
        <UserSelectField
          name="informationRequest.assignedUserId"
          label="Assigned User"
        />
        <DepartmentSelectField
          name="informationRequest.departmentId"
          label="Department"
        />
        <ProjectTypeField
          name="informationRequest.projectTypeId"
          label="Project Type"
        />
        <RequestLevelSelectField
          name="informationRequest.requestLevelId"
          label="Request Level"
        />
      </div>
      <div style={{ flex: 1 }}>
        <StringField name="informationRequest.title" label={'Title'} />
        <NotesField name="message.body" label={'Message'} />
        <Button
          type="submit"
          intent="success"
          large={true}
          style={{ float: 'right' }}
        >
          Submit
        </Button>
      </div>
    </div>
  </Form>
);

export default CreateInformationRequestForm;
