import * as React from 'react';
import { Spinner } from '@blueprintjs/core';

const Loading = (props: { children?: React.ReactNode; }) => {
  return (
    <div style={{ margin: 30 }}>
      <Spinner size={100} />
      {props.children}
    </div>
  );
}
export default Loading;
