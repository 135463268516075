import { Label } from '@blueprintjs/core';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  InformationRequest,
  InformationRequestUpdateInput
} from '../../../models/models';
import * as React from 'react';

import DateField from '../../Form/Field/DateField';
import StringField from '../../Form/Field/StringField';
import UserSelectField from '../../Form/Field/UserSelectField';
import DepartmentSelectField from '../../Form/Field/DepartmentSelectField';
import { RouteComponentProps } from 'react-router';
import { InformationRequestQuery } from '../../../graphql/query/InformationRequest';
import { UpdateInformationRequestMutation } from '../../../graphql/mutation/InformationRequest';
import UpdateSaveButton from '../../Form/UpdateSaveButton';
import ListMessageViews from '../../Message/ListMessageViews';
import CreateMessage from '../../Message/CreateMessage';
import ProjectTypeField from '../../Form/Field/ProjectTypeField';
import RequestLevelSelectField from '../../Form/Field/RequestLevelField';
import InformationRequestStatusSelectField from '../../Form/Field/InformationRequestStatusField';
import moment from 'moment';

const createInformationRequestUpdateInput = (
  values: InformationRequest
): InformationRequestUpdateInput => {
  const update: InformationRequestUpdateInput = {
    id: values.id,
    departmentId: values.departmentId,
    assignedUserId: values.assignedUserId,
    informationRequestStatusId: values.informationRequestStatusId,
    modelNumber: values.modelNumber,
    projectNumber: values.projectNumber,
    projectTypeId: values.projectTypeId,
    requestLevelId: values.requestLevelId,
    requestingUserId: values.requestingUserId,
    title: values.title,
    requestedDate: values.requestedDate
  };
  return update;
};

const ViewInformationRequestView = (
  props: RouteComponentProps<{ id: string }>
) => {
  const { id } = props.match.params;
  const { loading: loading2, error, data } = InformationRequestQuery({ id });
  const [
    updateInformationRequest,
    { loading }
  ] = UpdateInformationRequestMutation();

  const submitForm = (
    values: InformationRequest,
    { setSubmitting, resetForm }: FormikHelpers<InformationRequest>
  ) => {
    setSubmitting(true);
    const update = createInformationRequestUpdateInput(values);

    updateInformationRequest({
      variables: { informationRequest: update }
    }).then(values => {
      if (values.errors) {
        console.log(values.errors);
        return;
      }
      resetForm({ values: values.data!.updateInformationRequest });
      setSubmitting(false);
    });
  };

  if (loading2) {
    return <h1>Loading...</h1>;
  }
  if (error || data == null) {
    return <h1>error</h1>;
  }
  return (
    <div>
      <h2>Information Request: {data.informationRequest.title}</h2>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Formik
          initialValues={data.informationRequest}
          onSubmit={submitForm}
          render={formikBag => RenderForm(formikBag, loading)}
        />
        <div style={{ flex: 1 }}>
          <ListMessageViews
            messages={data.informationRequest.informationRequestHasMessage.map(
              i => i.message
            )}
          />
          <CreateMessage informationRequestId={data.informationRequest.id} />
        </div>
      </div>
    </div>
  );
};

const RenderForm = (
  formikBag: FormikProps<InformationRequest>,
  loading: boolean
) => (
  <Form style={{ margin: 30 }}>
    <Label>Created</Label>
    <p>
      <b>{moment(new Date(formikBag.values.createdAt)).format('LLL')}</b>
    </p>
    <StringField name="modelNumber" label={'Model Number'} />
    <StringField name="projectNumber" label={'Project Number'} />
    <DateField name="requestedDate" label="Requested Date" />
    <UserSelectField name="assignedUserId" label="Assigned User" />
    <DepartmentSelectField name="departmentId" label="Department" />
    <ProjectTypeField name="projectTypeId" label="Project Type" />
    <RequestLevelSelectField name="requestLevelId" label="Request Level" />
    <InformationRequestStatusSelectField
      name="informationRequestStatusId"
      label="Status"
    />
    {!formikBag.values.closedDate ? (
      <></>
    ) : (
      <>
        <Label>Closed Date</Label>
        <p>
          {moment(new Date(formikBag.values.closedDate))
            .local()
            .format('LLL')}
        </p>
      </>
    )}
    <UpdateSaveButton formikBag={formikBag} loading={loading} />
  </Form>
);

export default ViewInformationRequestView;
