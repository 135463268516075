import { Button } from '@blueprintjs/core';
import { Form, Formik, FormikProps, FormikHelpers } from 'formik';
import * as React from 'react';
import NotesField from '../Form/Field/NotesField';
import StringField from '../Form/Field/StringField';
import { SignupUser } from '../../graphql/mutation/User';
import UserTitleField from '../Form/Field/UserTitleField';
import { SignupUserInput } from '../../models/models';
import Loading from '../Loading';

const initialValues: SignupUserInput = {
  email: '',
  firstName: '',
  lastName: '',
  notes: '',
  userTitleId: 0
};

const AdminSignupUser = () => {
  const [signupUser, { loading }] = SignupUser();

  const submitForm = (
    variables: SignupUserInput,
    { setSubmitting }: FormikHelpers<SignupUserInput>
  ) => {
    setSubmitting(true);
    signupUser({ variables: { user: variables } }).then(values => {
      if (values?.errors) {
        console.log(values.errors);
        return;
      }
      if (values?.data?.signupUser) {
        window.location.replace('/User/view/' + values.data.signupUser.id);
      }
      setSubmitting(false);
    });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      render={RenderForm}
    />
  );
};

const RenderForm = (formikBag: FormikProps<SignupUserInput>) => (
  <Form style={{ margin: 30 }}>
    <h2>Admin Signup User</h2>
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <div style={{ margin: 20 }}>
        <StringField name="email" label={'Email'} />
        <StringField name="firstName" label={'First Name'} />
        <StringField name="lastName" label={'Last Name'} />
        <NotesField name="notes" label={'Notes'} />
        <UserTitleField name="userTitleId" label={'User Title'} />
        <Button
          type="submit"
          intent="success"
          large={true}
          style={{ float: 'right' }}
        >
          Submit
        </Button>
      </div>
    </div>
  </Form>
);

export default AdminSignupUser;
