import { Alignment, AnchorButton, Icon, Navbar } from '@blueprintjs/core';
import * as React from 'react';
import RightNavBar from './RightNavBar';
import { Users } from '../../models/models';

interface Props {
  user?: Users;
  isExternal?: boolean;
}

class Nav extends React.Component<Props> {
  public render() {
    let internalButtons = (
      <Navbar.Group style={{float: "none"}}>
        <Navbar.Divider />
        <RightNavBar user={this.props.user} />
      </Navbar.Group>
    );
    if (this.props.isExternal) {
      internalButtons = (<></>);
    }
    return (
      <Navbar
        className="bp3-navbar bp3-dark"
        style={{ margin: 0, height: 50, paddingLeft: 5 }}
      >
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading style={{ display: 'flex' }}>
            <AnchorButton href="/" minimal large>
              <Icon
                icon="shield"
                iconSize={20}
                style={{ marginTop: 1, marginRight: 10 }}
              />{' '}
              Shield Air
            </AnchorButton>
          </Navbar.Heading>
        </Navbar.Group>
        {internalButtons}
      </Navbar>
    );
  }
}

export default Nav;
