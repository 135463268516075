import { FormGroup } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import { PartTrackerUpdateInput, PartTracker } from '../../../models/models';
import { RouteComponentProps } from 'react-router';
import * as React from 'react';
import { UPDATE_PART_TRACKER } from '../../../graphql/mutation/PartTracker';
import { useMutation } from '@apollo/react-hooks';
import { PART_TRACKER_ID_QUERY } from '../../../graphql/query/PartTracker';
import { useQuery } from '@apollo/react-hooks';
import NotesField from '../../Form/Field/NotesField';
import UserSelectWithUserTitleField from '../../Form/Field/UserSelectWithUserTitleField';
import DateField from '../../Form/Field/DateField';
import DepartmentSelectField from '../../Form/Field/DepartmentSelectField';
import AssemblyTeamField from '../../Form/Field/AssembalyTeamField';
import PartTrackerStatusField from '../../Form/Field/PartTrackerStatusField';
import DeliveryTypeField from '../../Form/Field/DeliveryTypeField';
import NumberField from '../../Form/Field/NumberField';
import StringField from '../../Form/Field/StringField';
import Column from '../../Layout/Column';
import ColumnContainer from '../../Layout/ColumnContainer';
import UpdateSaveButton from '../../Form/UpdateSaveButton';
import ErrorComponent from '../../ErrorComponent';
import Loading from '../../Loading';
import ReasonSelectField from '../../Form/Field/ReasonSelectField';

const Sub = (props: RouteComponentProps<{ id: string }>) => {
  const { id } = props.match.params;
  const { loading: loading2, error, data } = useQuery<
    { partTracker: PartTracker },
    { id: string }
  >(PART_TRACKER_ID_QUERY, { variables: { id } });
  const [updatePartTracker, { loading }] = useMutation<
    { updatePartTracker: PartTracker },
    { updatePartTracker: PartTrackerUpdateInput }
  >(UPDATE_PART_TRACKER);
  if (loading2 || loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorComponent />;
  }
  return (
    <Formik
      initialValues={data!.partTracker}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        const update: PartTrackerUpdateInput = {
          id: values.id,
          jobNumber: values.jobNumber,
          lineNumber: values.lineNumber,
          partName: values.partName,
          recNumber: values.recNumber,
          poNumber: values.poNumber,
          poDate: values.poDate,
          poRecievedDate: values.poRecievedDate,
          assemblyTeamId: values.assemblyTeamId,
          sasPartNumber: values.sasPartNumber,
          manPartNumber: values.manPartNumber,
          quantity: values.quantity,
          vendor: values.vendor,
          expectedDate: values.expectedDate,
          deliveryDate: values.deliveryDate,
          ecoNumber: values.ecoNumber,
          ecoNotes: values.ecoNotes,
          departmentId: values.departmentId,
          deliveryTypeId: values.deliveryTypeId,
          partsTrackerStatusId: values.partsTrackerStatusId,
          trackingNumber: values.trackingNumber,
          deliveryNotes: values.deliveryNotes,
          designRepId: values.designRepId,
          reason: values.reason,
          reasonNotes: values.reasonNotes,
          projectManagerId: values.projectManagerId,
          requestedDate: values.requestedDate
        };
        updatePartTracker({ variables: { updatePartTracker: update } }).then(
          values => {
            if (values.errors) {
              return;
            }
            if (
              values != null &&
              values.data != null &&
              values.data.updatePartTracker != null
            ) {
            }
            setSubmitting(false);
          }
        );
      }}
      render={formikBag => (
        <Form style={{ margin: 30 }}>
          <h2>View/Edit Part Request</h2>
          <ColumnContainer>
            <Column>
              <StringField label='Job Number' name='jobNumber' />
              <StringField label='Line Number' name='lineNumber' />
              <ReasonSelectField label="Reason" name="reason" />
              <NumberField label="Quantity" name="quantity" />
              <StringField label='Part Name' name='partName' />
              <StringField label="SAS Part #" name='sasPartNumber' />
              <StringField label="Man Part #" name='manPartNumber' />
              <StringField label="Vendor" name='vendor' />
              <StringField label="Rec Number" name='recNumber' />
              <StringField label="PO Number" name='poNumber' />
              <StringField label="ECO Number" name='ecoNumber' />
              <NotesField label="ECO Notes" name="ecoNotes" />
              <NotesField label="Reason Notes" name="reasonNotes" />
            </Column>
            <Column>
              <DateField name="requestedDate" label="Requested Date" />
              <DateField name="poDate" label="PO Date" />
              <DateField name="poRecievedDate" label="PO Recieved Date" />
              <DateField name="expectedDate" label="Expected Date" />
              <DateField name="deliveryDate" label="Delivery Date" />
              <DepartmentSelectField name="departmentId" label="Department" />
              <AssemblyTeamField label="Assembly Team" name="assemblyTeamId" />
              <PartTrackerStatusField
                label="Status"
                name="partsTrackerStatusId"
              />
              <UserSelectWithUserTitleField
                label="Project Manager"
                name="projectManagerId"
                userTitle="Project Manager"
              />
              <UserSelectWithUserTitleField
                label="Design Rep"
                name="designRepId"
                userTitle="Lead Engineer"
              />  <FormGroup label={"Created By"}>
              <>{data!.partTracker!.createdByUser?.firstName} {data!.partTracker!.createdByUser?.lastName}</>
              </FormGroup>
              <NotesField label="Reason Notes" name="reasonNotes" />
              <DeliveryTypeField label="Delivery Type" name="deliveryTypeId" />
              <StringField label={'Tracking #'} name="trackingNumber" />
              <NotesField label="Delivery Notes" name="deliveryNotes" />
              <UpdateSaveButton formikBag={formikBag} loading={loading}/>
            </Column>
          </ColumnContainer>
        </Form>
      )}
    />
  );
};
export default Sub;
