import gql from 'graphql-tag';
import { FULL_USER_FRAGMENT } from './User';

export const FULL_INFORMATION_REQUEST_FRAGMENT = gql`
  fragment FullInformationRequest on InformationRequest {
    id
    requestingUserId
    projectTypeId
    projectNumber
    requestLevelId
    modelNumber
    departmentId
    informationRequestStatusId
    title
    assignedUserId
    requestedDate
    createdAt
    closedDate
    requestLevel {
      id
      name
    }
    projectType {
      id
      name
    }
    informationRequestStatus {
      id
      name
    }
    assignedUser {
      ...FullUser
    }
    department {
      id
      name
    }
    requestingUser {
      ...FullUser
    }
  }
  ${FULL_USER_FRAGMENT}
`;
