import {
  firstNameCol,
  dateCol,
  intCol,
  idCol,
  notesCol,
  charCol,
  strCol
} from './ColDef';
import { ColGroupDef, ColDef, SideBarDef } from 'ag-grid-community';
export const getCustomer = (str: string): ColGroupDef => {
  return {
    headerName: 'Customer',
    children: [
      {
        headerName: 'Name',
        field: str + 'customer.name',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        chartDataType: 'category'
      }
    ]
  };
};
export const genDeliveryType = (accessString: string): ColGroupDef => {
  return {
    headerName: 'Delivery Type',
    children: [
      {
        headerName: 'ID',
        field: accessString + 'id',
        ...intCol,
        cellRenderer: params => {
          if (!params.value) {
            return '';
          }
          return `<a href="/deliverytype/view/${params.value}" rel="noopener" target="_blank">${params.value}</a>`;
        }
      },
      {
        headerName: 'Name',
        field: accessString + 'name',
        ...strCol
      }
    ]
  };
};
export const genDepartments = (accessString: string): ColGroupDef => {
  return {
    headerName: 'Departments',
    children: [
      {
        headerName: 'ID',
        field: accessString + 'id',
        ...intCol,
        cellRenderer: params => {
          if (!params.value) {
            return '';
          }
          return `<a href="/view/department/${params.value}" rel="noopener" target="_blank">${params.value}</a>`;
        }
      },
      {
        headerName: 'Name',
        field: accessString + 'name',
        ...strCol
      }
    ]
  };
};

export const genDepartmentUserList = (accessString: string): ColGroupDef => {
  return {
    headerName: 'Department User List',
    children: [
      {
        headerName: 'ID',
        field: accessString + 'user.id',
        ...intCol,
        cellRenderer: params => {
          if (!params.value) {
            return '';
          }
          return `<a href="/user/view/${params.value}" rel="noopener" target="_blank">${params.value}</a>`;
        }
      },
      {
        headerName: 'First Name',
        field: accessString + 'user.firstName',
        ...strCol
      },
      {
        headerName: 'Last Name',
        field: accessString + 'user.lastName',
        ...strCol
      },
      {
        headerName: 'Email',
        field: accessString + 'user.email',
        ...strCol
      }
    ]
  };
};
export const genUserCols = (accessString: string): (ColGroupDef | ColDef)[] => {
  return [
    {
      headerName: 'First Name',
      field: accessString + '.firstName',
      ...strCol
    },
    {
      headerName: 'Last Name',
      field: accessString + '.lastName',
      ...firstNameCol
    }
  ];
};
export const getDrawing = (str: string): ColGroupDef => {
  return {
    headerName: 'Drawing',
    children: [
      {
        headerName: 'Drawing Due Date',
        field: str + 'drawingDueDate',
        ...dateCol
      },
      {
        headerName: 'Submitted Date',
        field: str + 'drawingSubmittedDate',
        ...dateCol
      },
      {
        headerName: 'Returned Date',
        field: str + 'drawingReturnedDate',
        ...dateCol
      },
      {
        headerName: 'Resubmit Milestone Date',
        field: str + 'drawingResubmitMilestoneDate',
        ...dateCol
      },
      {
        headerName: 'Resubmitted Date',
        field: str + 'drawingResubmittedDate',
        ...dateCol
      },
      {
        headerName: 'Resubmit Amount',
        field: str + 'drawingResubmitAmount',
        ...intCol
      },
      {
        headerName: 'Drawing Approved Date',
        field: str + 'drawingApprovedDate',
        ...dateCol
      }
    ]
  };
};
export const getSale = (str: string): ColGroupDef => {
  const saleStr = str + 'sale.';
  return {
    headerName: 'Sale',
    children: [
      {
        headerName: 'PO Number',
        field: saleStr + 'purchaseOrderNumber',
        ...idCol
      },
      {
        headerName: 'PO Date',
        field: saleStr + 'purchaseOrderDate',
        ...dateCol
      },
      {
        headerName: 'PO Received Date',
        field: saleStr + 'purchaseOrderReceivedDate',
        ...dateCol
      },
      {
        headerName: 'Promised Ship Date',
        field: saleStr + 'promisedShipDate',
        ...dateCol
      },
      {
        headerName: 'Quote Number',
        field: saleStr + 'quoteNumber',
        ...idCol
      },
      {
        headerName: 'Notes',
        field: saleStr + 'notes',
        ...notesCol
      },
      {
        headerName: 'Representative',
        children: genUserCols('sale.representative')
      }
    ]
  };
};
export const getJob = (str: string): ColGroupDef => {
  return {
    headerName: 'Job',
    children: [
      {
        headerName: 'Number',
        field: str + 'number',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        chartDataType: 'series',
        enablePivot: true
      },
      {
        headerName: 'State',
        field: str + 'jobState.name',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        chartDataType: 'category'
      },
      {
        headerName: 'Project Manager',
        children: genUserCols(str + 'projectManager')
      },
      {
        headerName: 'Notes',
        field: str + 'notes',
        sortable: true,
        filter: true
      },
      getCustomer(str)
    ]
  };
};

export const getEngineering = (str: string): ColGroupDef => {
  const engStr = str + 'engineering.';
  return {
    headerName: 'Engineering',
    children: [
      {
        headerName: 'Assigned Date',
        field: engStr + 'assignedDate',
        ...dateCol
      },
      getDrawing(engStr),

      {
        headerName: 'Long Lead',
        children: [
          {
            headerName: 'has',
            field: engStr + 'longLead',
            ...charCol
          },
          {
            headerName: 'Milestone Date',
            field: engStr + 'longLeadMilestoneDate',
            ...dateCol
          },
          {
            headerName: 'Actual Date',
            field: engStr + 'longLeadActualDate',
            ...dateCol
          }
        ]
      },
      {
        headerName: 'Program Required',
        children: [
          {
            headerName: 'has',
            field: engStr + 'programRequired',
            ...charCol
          },
          {
            headerName: 'Milestone Date',
            field: engStr + 'programMilestoneDate',
            ...dateCol
          },
          {
            headerName: 'Actual Date',
            field: engStr + 'programCompletionDate',
            ...dateCol
          }
        ]
      },
      {
        headerName: 'Stack',
        children: [
          {
            headerName: 'has',
            field: engStr + 'stack',
            ...charCol
          },
          {
            headerName: 'Released Date',
            field: engStr + 'stackReleasedDate',
            ...dateCol
          },
          {
            headerName: 'Approved Date',
            field: engStr + 'stackApprovedDate',
            ...dateCol
          }
        ]
      },
      {
        headerName: 'Duct',
        children: [
          {
            headerName: 'has',
            field: engStr + 'duct',
            ...charCol
          },
          {
            headerName: 'Released Date',
            field: engStr + 'ductReleaseDate',
            ...dateCol
          },
          {
            headerName: 'Approved Date',
            field: engStr + 'ductApprovedDate',
            ...dateCol
          }
        ]
      },
      {
        headerName: 'BOM Milestone',
        field: engStr + 'bomMilestoneDate',
        ...dateCol
      },
      {
        headerName: 'BOM Actual',
        field: engStr + 'bomActualDate',
        ...dateCol
      },
      {
        headerName: 'Release To Production Date',
        field: engStr + 'releaseToProductionDate',
        ...dateCol
      },
      {
        headerName: 'Notes',
        field: engStr + 'notes',
        ...notesCol
      }
    ]
  };
};

export const standardSideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel'
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel'
    }
  ]
};
