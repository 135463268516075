import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {
  ColGroupDef,
  SideBarDef
} from 'ag-grid-community';

import 'ag-grid-enterprise';
import { Button, ButtonGroup } from '@blueprintjs/core';
import Loading from '../components/Loading';
import { getSurveyResponse } from '../Table/SurveyResponseColDef';
import { SurveyResponse } from '../models/models';
import { SurveyResponsesQuery } from '../graphql/query/survey';
import ErrorComponent from '../components/ErrorComponent';

const colGroup: ColGroupDef[] = [getSurveyResponse('')];
const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel'
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel'
    }
  ]
};

function SurveyResponsesTable() {

  const [graphqlData, setGraphqlData] = useState<SurveyResponse[]>();
  const [ref, setRef] = useState<boolean>(true);
  const [isPolling, setIsPolling] = useState<boolean>(true);
  const { loading, error, data, refetch } = SurveyResponsesQuery();
  const refetchQuery = () => {
    setRef(true);
    refetch();
  };
  if (loading) {
    return <Loading />;
  }
  if (!data) {
    return <ErrorComponent errorText="Could Not Find Data" />;
  }
  if (error) {
    return <p>{error.message}</p>;
  }
  if (ref) {
    setRef(false);
    setGraphqlData(data.surveyResponses);
    if (isPolling) {
      setTimeout(() => refetchQuery(), 300000);
    }
  }

  return (
    <div style={{ height: 'calc(100vh - 50px)' }}>
      <div style={{ display: 'block', height: 50 }}>
        <div>
          <h2 style={{ display: 'inline' }}>Survey Responses</h2>
          <div
            style={{ display: 'flex', float: 'right', verticalAlign: 'center' }}
          >
            <p>Refreshes Every 5 minutes if autorefresh is on </p>
            <ButtonGroup>
              <Button icon="refresh" onClick={() => refetchQuery()}>
                Refresh
              </Button>
              <Button
                intent={isPolling ? 'success' : 'danger'}
                onClick={() => setIsPolling(!isPolling)}
              >
                {isPolling ? 'Disable Auto Refresh' : 'Enable Auto Refresh'}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
      <div
        style={{ height: 'calc(100vh - 100px)' }}
        className="ag-theme-balham"
      >
        <AgGridReact
          columnDefs={colGroup}
          enableRangeSelection={true}
          sideBar={sideBar}
          enableCharts={true}
          floatingFilter={true}
          rememberGroupStateWhenNewData={true}
          enableCellChangeFlash={true}
          animateRows={true}
          deltaRowDataMode={true}
          getRowNodeId={(data: any) => data.id}
          rowData={graphqlData}
        ></AgGridReact>
      </div>
    </div>
  );
}

export default SurveyResponsesTable;
