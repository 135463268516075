import * as React from 'react';
import { JOBS } from '../../../graphql/query/jobs';

import { useQuery } from '@apollo/react-hooks';
import { Jobs } from '../../../models/models';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { ColGroupDef, SideBarDef } from 'ag-grid-community';

import 'ag-grid-enterprise';
import { getEngineering, getJob } from '../../../Table/ColGroupDef';
import { Spinner } from '@blueprintjs/core';

interface JobArray {
  jobs: Jobs[];
}

function App() {
  const colGroup: ColGroupDef[] = [
    getJob(''),
    getEngineering(''),
    {
      headerName: 'Acknowledgement',
      children: []
    },
    {
      headerName: 'Lines',
      children: [
        {
          headerName: 'Number',
          field: 'lineNumber'
        }
      ]
    }
  ];
  const sideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel'
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel'
      }
    ]
  };
  const { loading, error, data } = useQuery<JobArray>(JOBS);
  if (loading) {
    return <Spinner size={400} />;
  }
  if (!data) {
    return <p>No Data!</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }
  return (
    <div style={{ height: 'calc(100vh - 50px)' }} className="ag-theme-balham">
      <AgGridReact
        columnDefs={colGroup}
        enableRangeSelection={true}
        sideBar={sideBar}
        enableCharts={true}
        rowData={data!.jobs}
      ></AgGridReact>
    </div>
  );
}

/*
var arr: {a:Number, b: Array<Number>} = {
  a: 3,
  b: [1, 2, 3]
}

function dataCon(arr: any) {
  function copy(arr: any, fieldStr: string, index: number){
    let field = arr[fieldStr][index];
    if (arr[fieldStr].length > index) {
      let ret = _.cloneDeep(arr);
      ret[fieldStr] = field;
      return ret;
    }
  }
  let retArr = [];
  for (let i = 0; i < arr.b.length; i++){
    retArr.push(copy(arr, "b", i))
  }
  return retArr
} 
function dataCon2Arr(arr: any[]) {
  let ret:any[] = [];
  for (let i = 0; i < arr.length; i++){
    ret = ret.concat(dataCon2(arr[i]));
  }
  return ret;
}
function dataCon2(arr: any) {
  function copy(arr: any, fieldStr: string, index: number){
    let field = _.get(arr, fieldStr)[index];
    console.log(field);
    if (_.get(arr, fieldStr).length > index) {
      let ret = _.cloneDeep(arr);
      _.set(ret, fieldStr, field);
      console.log(ret);
      return ret;
    }
  }
  let retArr = [];
  console.log(arr.sale.equipmentModelSales);
  for (let i = 0; i < arr.sale.equipmentModelSales.length; i++){
    retArr.push(copy(arr, "sale.equipmentModelSales", i))
  }
  return retArr
}*/

export default App;
