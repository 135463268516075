import * as React from 'react';
import Navbar from './navbar/NavBar';
import { Users } from '../models/models';

const Layout = (props: { children?: React.ReactNode; user?: Users, isExternal?: boolean }) => {
  
  return (
  <React.Fragment>
    <Navbar user={props.user} isExternal={props.isExternal} />
    {props.children}
  </React.Fragment>
);
  }
export default Layout;
