import gql from 'graphql-tag';
import { FULL_USER_FRAGMENT } from '../Fragment/User';

export const FULL_ENGINEERING_FRAGMENT = gql`
  fragment FullEngineering on Engineering {
    designRepresentative {
      ...FullUser
    }
    assignedDate
    drawingDueDate
    drawingSubmittedDate
    drawingReturnedDate
    drawingResubmitMilestoneDate
    drawingResubmittedDate
    drawingResubmitAmount
    drawingApprovedDate
    longLead
    longLeadMilestoneDate
    longLeadActualDate
    programRequired
    programMilestoneDate
    programCompletionDate
    stack
    stackReleasedDate
    stackApprovedDate
    duct
    ductReleasedDate
    ductApprovedDate
    bomMilestoneDate
    bomActualDate
    compDesPlanDate
    releaseToProductionDate
    notes
  }
  ${FULL_USER_FRAGMENT}
`;
