import * as React from 'react';

interface Props {
    errorText?: string;
    children?: React.ReactNode;
}
const ErrorComponent = (props: Props) => (
  <div>
    <h1>An Error Has Occurred</h1>
    <p>Please try again. Its either a server error or this is not a supported action.</p>
    <p>{props.errorText}</p>
    {props.children}
  </div>
);

export default ErrorComponent;
