import * as React from 'react';
import { useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import { Button, Card, Elevation } from '@blueprintjs/core';
import { LoginMutation } from '../graphql/mutation/Login';
import InputField from '../components/Form/Field/InputField';
import { Link } from 'react-router-dom';

interface MyFormValues {
  email: string;
  password: string;
}


export const Login = () => {
  const [updatePartTracker, response] = LoginMutation();
  const [actions, setActions] = useState<FormikHelpers<MyFormValues>>();
  if (actions) {
    if (response && response.error) {
      response.error.graphQLErrors.map(error => {
        if (error.path) {
          return error.path.map(path => {
            return actions.setFieldError(path.toString(), error.message);
          });
        }
        return null;
      });
    }
  }
  const handleSubmit = (
    values: MyFormValues,
    actions: FormikHelpers<MyFormValues>
  ) => {
    updatePartTracker({ variables: values });
    setActions(actions);
  };

  const { loading, data } = response;
  if (!loading && data !== undefined) {
    if (data.login) {
      localStorage.setItem('token', data.login);
    }
  }

  if (!loading && localStorage.getItem('token') !== null){
    document.location.replace("/");
  }


  const second = () => {
    return (
      <Card elevation={Elevation.TWO} style={{ minWidth: '25%' }}>
        <h2>Login</h2>
        <Form>
          <InputField name="email" type="email" placeholder="Email" />
          <InputField name="password" type="password" placeholder="Password" />
          <Button
            type="submit"
            className="bp3-fill"
            intent="success"
            style={{ marginTop: 10 }}
            disabled={loading}
          >
            Submit
          </Button>
          <p style={{color: "red"}}>{response.error?.networkError && "A network error has occurred."}</p>
          <Link to="/PasswordReset" style={{ float: 'right', marginTop: 10 }}>
            Forgot Password?
          </Link>
        </Form>
      </Card>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        render={second}
      />
    </div>
  );
};
