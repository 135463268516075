import * as React from "react";
import { ListSurveyTemplatesQuery } from "../graphql/query/survey";
import SurveyTemplateCard from "./SurveyTemplateCard";
import Loading from "../components/Loading";
import ErrorComponent from "../components/ErrorComponent";
const ListSurveyTemplates = () => {
    const { loading, error, data } = ListSurveyTemplatesQuery();
    if (loading) {
        return <Loading />;
    }
    if (!data) {
        return <ErrorComponent />;
    }
    if (error) {
        return <ErrorComponent errorText={error.message} />;
    }
    const lst = data.surveyTemplates.map(val => <SurveyTemplateCard surveyTemplate={val} />);
    return (
        <div>
            {lst}
        </div>);
}

export default ListSurveyTemplates;