import * as React from 'react';
import { useState } from 'react';
import {
  Formik,
  FormikHelpers,
  Form,
} from 'formik';
import { Button, Card, Elevation } from '@blueprintjs/core';
import { RouteComponentProps, Redirect } from 'react-router';
import { RequestPasswordReset } from '../../graphql/mutation/PasswordReset';
import InputField from '../Form/Field/InputField';

interface MyFormValues {
  email: string;
}

type Props = RouteComponentProps;

export const PasswordReset = (props: Props) => {
  const [requestPasswordReset, response] = RequestPasswordReset();
  const [redirect, setRedirect] = useState<boolean>(false);

  const handleSubmit = (
    values: MyFormValues,
    actions: FormikHelpers<MyFormValues>
  ) => {
    requestPasswordReset({ variables: values });
    setRedirect(true);
  };

  const { loading } = response;

  if (redirect) return <Redirect to={'/PasswordReset/Response'} />;

  const second = () => {
    return (
      <Card elevation={Elevation.TWO} style={{ minWidth: '25%' }}>
        <h2>Request Password Reset</h2>
        <Form>
          <InputField name="email" type="email" placeholder="Email" />
          <Button
            type="submit"
            className="bp3-fill"
            intent="success"
            style={{ marginTop: 10 }}
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      </Card>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        render={second}
      />
    </div>
  );
};
