import { Select, ItemRenderer } from '@blueprintjs/select';
import { InformationRequestStatus } from '../../../models/models';
import { Button, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
import { InformationRequestStatueses } from '../../../graphql/query/InformationRequestStatus';

const MySelect = Select.ofType<InformationRequestStatus>();

const itemRenderer: ItemRenderer<InformationRequestStatus> = (
  { id, name },
  { handleClick, index, modifiers }
) => (
  <MenuItem
    key={id}
    onClick={handleClick}
    tabIndex={index}
    style={{
      fontSize: 16,
      fontWeight: 'bolder',
      margin: 2,
      textAlign: 'center'
    }}
    text={name}
  />
);
interface MyProps {
  selectedId: number;
  onItemSelect: (
    item: InformationRequestStatus,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void;
}

const InformationRequestStatusSelect = (props: MyProps) => {
  const { loading, error, data } = InformationRequestStatueses();
  if (loading) {
    return <p>loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  if (data == null || data.informationRequestStatuses == null) {
    return <p>is null</p>;
  }
  const find = data.informationRequestStatuses.find(
    a => a.id === props.selectedId
  );
  let text = 'NONE';
  if (find) {
    text = find.name;
  }
  return (
    <MySelect
      items={data.informationRequestStatuses}
      itemRenderer={itemRenderer}
      onItemSelect={props.onItemSelect}
      noResults={<MenuItem disabled={true} text="No results." />}
      className="bp3-fill"
    >
      <Button
        text={text}
        rightIcon="double-caret-vertical"
        className="bp3-fill"
      />
    </MySelect>
  );
};
export default InformationRequestStatusSelect;
