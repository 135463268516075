import * as React from 'react';


const Column = (props: { children?: React.ReactNode; }) => {
  return (
    <div
      style={{
        margin: 20,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      }}
    >
      {props.children}
    </div>
  );
}

export default Column;