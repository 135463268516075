import {
  AnchorButton,
  IconName,
  MaybeElement,
  Menu,
  Popover,
  Position
} from "@blueprintjs/core";
import * as React from "react";

import { Users } from "../../models/models";

interface Props {
  user?: Users;
}

const getFullName = (user?: Users) => {
  if (!user) {
    return "";
  }
  return user.firstName + " " + user.lastName;
};
const NotLoginView = () => (
  <>
    <AnchorButton
      className="pt-minimal"
      href="/login"
      icon="log-in"
      text="Login"
    />
  </>
);
interface NavBarItem {
  href?: string;
  icon: IconName | MaybeElement;
  text: string;
  menuItems: MenuItem[];
}

type MenuItem = DividerMenuItem | TextMenuItem;

type DividerMenuItem = {
  kind: "DividerMenuItem";
};

type TextMenuItem = {
  kind: "TextMenuItem";
  icon?: IconName | MaybeElement;
  href?: string;
  text: string;
  onClick?: () => any;
};

const NavBarItems = ({ items }: { items: NavBarItem[] }) => (
  <>
    {items.map((item, index) => (
      <NavBarItem item={item} key={index} />
    ))}
  </>
);
const NavBarItem = ({ item }: { item: NavBarItem }) => {
  const Anchor = (
    <AnchorButton
      className="pt-minimal"
      href={item.href}
      icon={item.icon}
      text={item.text}
    />
  );
  if (!item.href) {
    return (
      <Popover
        minimal={true}
        content={<MenuItems items={item.menuItems} />}
        position={Position.BOTTOM}
      >
        {Anchor}
      </Popover>
    );
  }
  return <>{Anchor}</>;
};
const MenuItems = ({ items }: { items: MenuItem[] }) => {
  if (items.length === 0) {
    return <></>;
  }

  return (
    <Menu>
      {items.map((item, i) => (
        <MenuItem item={item} key={i} />
      ))}
    </Menu>
  );
};
const MenuItem = ({ item }: { item: TextMenuItem | DividerMenuItem }) => {
  switch (item.kind) {
    case "DividerMenuItem":
      return <Menu.Divider />;
    case "TextMenuItem":
      return <Menu.Item icon={item.icon} href={item.href} text={item.text} />;
  }
  return <></>;
};

const LoginView = ({ items }: { items: NavBarItem[] }) => (
  <NavBarItems items={items} />
);
const RightNavBar = (props: Props) => {
  const navBarItems: NavBarItem[] = [
    {
      icon: "clipboard",
      text: "Part Tracker",
      menuItems: [
        {
          kind: "TextMenuItem",
          href: "/parttracker",
          icon: "eye-open",
          text: "View Parts"
        },
        {
          kind: "TextMenuItem",
          href: "/parttracker/create",
          icon: "plus",
          text: "Create Part"
        }
      ]
    },
    {
      icon: "info-sign",
      text: "Info Requests",
      menuItems: [
        {
          kind: "TextMenuItem",
          href: "/InformationRequest",
          icon: "info-sign",
          text: "View Info Request"
        },
        {
          kind: "TextMenuItem",
          href: "/InformationRequest/create",
          icon: "plus",
          text: "Create Info Request"
        },
        {
          kind: "DividerMenuItem"
        },
        {
          kind: "TextMenuItem",
          href: "/deliverytype",
          icon: "drive-time",
          text: "View Delivery Type"
        },
        {
          kind: "TextMenuItem",
          href: "/deliverytype/create",
          icon: "plus",
          text: "Create Delivery Type"
        }
      ]
    }
  ];
  if (!props.user) {
    return <NotLoginView />;
  }
  if (
    new Set([
      "barry.loder@shieldair.com",
      "sheryll.dwyer@shieldair.com",
      "eric.fisk@shieldair.com",
      "aloder404@gmail.com"
    ]).has(props?.user?.email)
  ) {
    navBarItems.push({
      icon: "comment",
      text: "Surveys",
      menuItems: [
        {
          kind: "TextMenuItem",
          href: "/view/survey",
          icon: "eye-open",
          text: "View Survey Templates"
        },
        {
          kind: "TextMenuItem",
          href: "/view/surveyresponse",
          icon: "form",
          text: "View Survey Response"
        }
      ]
    });
    navBarItems.push({
      icon: "archive",
      text: "Departments",
      menuItems: [
        {
          kind: "TextMenuItem",
          href: "/view/departments",
          icon: "eye-open",
          text: "View Departments"
        }
      ]
    });
  }
  if (
    props &&
    props.user &&
    props.user.userTitle &&
    props.user.userTitle.name === "Admin"
  ) {
    const adminBar: NavBarItem = {
      icon: "power",
      text: "Admin",
      menuItems: [
        {
          kind: "TextMenuItem",
          icon: "new-object",
          text: "Signup New User",
          href: "/user/signup"
        },

        {
          kind: "TextMenuItem",
          icon: "key",
          text: "Send Password Reset",
          href: "/PasswordReset"
        },
        {
          kind: "TextMenuItem",
          icon: "user",
          text: "Manage Users",
          href: "/user/view"
        }
      ]
    };
    navBarItems.push(adminBar);
  }
  const userBar: NavBarItem = {
    icon: "user",
    text: `Welcome ${getFullName(props.user)}`,
    menuItems: [
      {
        kind: "TextMenuItem",
        icon: "log-out",
        text: "Logout",
        href: "/logout"
      }
    ]
  };
  navBarItems.push(userBar);

  return <LoginView items={navBarItems} />;
};

export default RightNavBar;
