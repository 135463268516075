import { FormGroup, Button } from '@blueprintjs/core';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Loading from '../Loading';
import ErrorComponent from '../ErrorComponent';
import { useDeliveryTypeQueryParamQuery, useUpdateDeliveryTypeMutation, DeliveryTypeUpdateInput } from '../../generated/graphql';

const Sub = (props: RouteComponentProps<{ id: string }>) => {
  const { id } = props.match.params;
  const { loading: loading2, error, data } = useDeliveryTypeQueryParamQuery({ variables: { id } });

  const [updateDeliveryType, { loading }] = useUpdateDeliveryTypeMutation();
  if (loading2 || loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorComponent />;
  }
  if (!data || !data.deliveryType) {
    return <ErrorComponent />;
  }
  return (
    <Formik
      initialValues={data.deliveryType}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        const update: DeliveryTypeUpdateInput = {
          id: values.id,
          name: values.name,
          isShipping: values.isShipping
        };
        updateDeliveryType({ variables: { updateDeliveryType: update } }).then(
          values => {
            if (values.errors) {
              console.log(values.errors);
              return;
            }
            if (
              values != null &&
              values.data != null &&
              values.data.updateDeliveryType != null
            ) {
              window.location.replace(
                '/deliverytype/view/' + values.data.updateDeliveryType.id
              );
            }
            setSubmitting(false);
          }
        );
      }}
      render={formikBag => (
        <Form style={{ margin: 30 }}>
          <h2>Update Delivery Type</h2>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div style={{ margin: 20 }}>
              <FormGroup label={'Name'}>
                <Field name="name" className="bp3-input bp3-fill" />
              </FormGroup>
              <Button
                hidden={!formikBag.dirty}
                type="submit"
                intent="success"
                large={true}
                style={{ float: 'right' }}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      )}
    />
  );
};
export default Sub;
