import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ME } from '../query/me';

export interface LoginResult {
  login: string;
}

export interface LoginArgs {
  email: string;
  password: string;
}

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const LoginMutation = () => {
  return useMutation<LoginResult, LoginArgs>(LOGIN_MUTATION, {
    onError: () => {}, refetchQueries: [{query:ME}]
  });
};
