import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { SurveyResponse, SurveyResponseCreateInput, SurveyResponseRespondInput, SurveyResponseUpdateInput } from '../../models/models';


export interface SurveyResponseCreateArgs {
  surveyResponseCreateInput: SurveyResponseCreateInput;
}

export interface SurveyResponseCreateResponse {
  createSurveyResponse?: SurveyResponse;
}
export const SURVEY_RESPONSE_CREATE_MUTATION = gql`
  mutation createSurveyResponse($surveyResponseCreateInput: SurveyResponseCreateInput!) {
    createSurveyResponse(surveyResponseCreateInput: $surveyResponseCreateInput) {
      id
      uri
    }
  }
`;
export const SurveyResponseCreateMutation = () => {
  return useMutation<SurveyResponseCreateResponse, SurveyResponseCreateArgs>(
    SURVEY_RESPONSE_CREATE_MUTATION,
    { onError: () => {} }
  );
};


export interface SurveyResponseUpdateArgs {
  surveyResponseUpdateInput: SurveyResponseUpdateInput;
}

export interface SurveyResponseUpdateResponse {
  updateSurveyResponse?: SurveyResponse;
}
export const SURVEY_RESPONSE_UPDATE_MUTATION = gql`
  mutation updateSurveyResponse($surveyResponseUpdateInput: SurveyResponseUpdateInput!) {
    updateSurveyResponse(surveyResponseUpdateInput: $surveyResponseUpdateInput) {
      id
      hiddenNotes
      title
      companyName
    }
  }
`;
export const SurveyResponseUpdateMutation = () => {
  return useMutation<SurveyResponseUpdateResponse, SurveyResponseUpdateArgs>(
    SURVEY_RESPONSE_UPDATE_MUTATION,
    { onError: () => {} }
  );
};


export interface RespondSurveyResponseArgs {
  surveyResponseRespondInput: SurveyResponseRespondInput;
}
export interface RespondSurveyResponseResponse {
  createSurveyResponse?: SurveyResponse;
}
export const RESPOND_SURVEY_RESPONSE_MUTATION = gql`
  mutation respondSurveyResponse($surveyResponseRespondInput: SurveyResponseRespondInput!) {
    respondSurveyResponse(surveyResponseRespondInput: $surveyResponseRespondInput) {
      id
    }
  }
`;
export const RespondSurveyResponseMutation = () => {
  return useMutation<RespondSurveyResponseResponse, RespondSurveyResponseArgs>(
    RESPOND_SURVEY_RESPONSE_MUTATION,
    { onError: () => {} }
  );
};
