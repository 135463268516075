import gql from 'graphql-tag';
import { FULL_ACK_FRAGMENT } from './acknowledgement';
import { FULL_CUSTOMER_FRAGMENT } from './customer';
import { FULL_USER_FRAGMENT } from '../Fragment/User';
import { FULL_ENGINEERING_FRAGMENT } from './engineering';

export const SHALLOW_JOB_FRAGMENT = gql`
  fragment ShallowJob on Job {
    number
    crating
    deleted
    expedited
    fatSat
    notes
    quotedLeadTime
    thirdPartyCertification
  }
`;

export const JOBS = gql`
  query jobs {
    jobs {
      ...ShallowJob
      jobState {
        name
      }
      acknowledgement {
        ...FullAck
      }
      customer {
        ...FullCustomer
      }
      projectManager {
        ...FullUser
      }
      engineering {
        ...FullEngineering
      }
      lines {
        lineNumber
      }
    }
  }
  ${FULL_USER_FRAGMENT}
  ${FULL_CUSTOMER_FRAGMENT}
  ${SHALLOW_JOB_FRAGMENT}
  ${FULL_ACK_FRAGMENT}
  ${FULL_ENGINEERING_FRAGMENT}
`;

export const FULL_JOB_NO_LINES_FRAGMENT = gql`
  fragment FullJobNoLines on Job {
    ...ShallowJob
    jobState {
      name
    }
    acknowledgement {
      ...FullAck
    }
    customer {
      ...FullCustomer
    }
    projectManager {
      ...FullUser
    }
    engineering {
      ...FullEngineering
    }
  }
  ${FULL_USER_FRAGMENT}
  ${FULL_CUSTOMER_FRAGMENT}
  ${SHALLOW_JOB_FRAGMENT}
  ${FULL_ACK_FRAGMENT}
  ${FULL_ENGINEERING_FRAGMENT}
`;
