import { Select, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { UserTitles } from '../../../models/models';
import { Button, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
import { UserTitleAllQuery } from '../../../graphql/query/user';

const MySelect = Select.ofType<UserTitles>();

const itemRenderer: ItemRenderer<UserTitles> = (
  { id, name },
  { handleClick, index }
) => (
  <MenuItem
    key={id}
    onClick={handleClick}
    tabIndex={index}
    style={{
      fontSize: 16,
      fontWeight: 'bolder',
      margin: 2,
      textAlign: 'center'
    }}
    text={name}
  />
);

export const itemFilter: ItemPredicate<UserTitles> = (
  query,
  requestLevel,
  _index,
  exactMatch
) => {
  const normalizedName = requestLevel.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  } else {
    return normalizedName.indexOf(normalizedQuery) >= 0;
  }
};

interface MyProps {
  selectedId: number;
  onItemSelect: (
    item: UserTitles,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void;
}

const UserTitleSelect = (props: MyProps) => {
  const { loading, error, data } = UserTitleAllQuery();
  if (loading) {
    return <p>loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  if (data == null || data.userTitles == null) {
    return <p>is null</p>;
  }
  const find = data.userTitles.find(a => a.id === props.selectedId);
  let text = 'NONE';
  if (find) {
    text = find.name;
  }
  return (
    <MySelect
      items={data.userTitles}
      itemRenderer={itemRenderer}
      onItemSelect={props.onItemSelect}
      itemPredicate={itemFilter}
      noResults={<MenuItem disabled={true} text="No results." />}
      className="bp3-fill"
    >
      <Button
        text={text}
        rightIcon="double-caret-vertical"
        className="bp3-fill"
      />
    </MySelect>
  );
};
export default UserTitleSelect;
