import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { FormGroup } from '@blueprintjs/core';
import RequestLevelSelect from '../Select/RequestLevelSelect';

interface MyProps {
  label: string;
  name: string;
}

const RequestLevelFilterSelect = ({ field, form }: FieldProps) => {
  return (
    <RequestLevelSelect
      selectedId={field.value}
      onItemSelect={(item, e) => form.setFieldValue(field.name, item.id)}
    />
  );
};
const RequestLevelSelectField = (props: MyProps) => (
  <FormGroup label={props.label} className="bp3-fill">
    <Field
      name={props.name}
      render={(fieldProps: FieldProps) => RequestLevelFilterSelect(fieldProps)}
    />
  </FormGroup>
);

export default RequestLevelSelectField;
