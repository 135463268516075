/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateInput } from '@blueprintjs/datetime';
import * as React from 'react';
import moment from 'moment';

class MyDatePicker extends React.Component<MyDatePickerProps> {
  public render() {
    const { value, showActionBar, name, onChange } = this.props;
    return (
      <DateInput
        className="pt-fill"
        value={value}
        showActionsBar={showActionBar}
        formatDate={date => moment(date).format('L')}
        parseDate={str => {
          return new Date(str);
        }}
        placeholder={'M/D/YYYY'}
        todayButtonText={'Today'}
        minDate={new Date("01/01/2010")}
        maxDate={new Date("12/31/2030")}
        onChange={date => {
          const ret = { target: { value: date, name } };
          if (onChange) {
            onChange(ret);
          }
        }}
      />
    );
  }
}
interface MyDatePickerProps {
  showActionBar?: boolean;
  name: string;
  value: Date | null;
  onChange(event: any): any;
}
export default MyDatePicker;
