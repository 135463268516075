import * as Survey from "survey-react";
import "survey-react/survey.css"
import * as React from "react";
import { generateSurveyModel } from "./processSurvey";
import { GetSurveyTemplate } from "../graphql/query/survey";
const onComplete = (survey: any, options: any) => {
    //Write survey results into database
    console.log("Survey results: " + JSON.stringify(survey.data));
   }
export const SurveyForm = () => {
    const { loading, error, data } = GetSurveyTemplate({ id: '1'})
    if (loading) {
        return <p>Loading</p>;
    }
  if (!data) {
    return <p>dataNotFound</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

    return <Survey.Survey model={generateSurveyModel(data.surveyTemplate)} onComplete={onComplete} />
}