import * as React from 'react';
import { useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import { Button, Card, Elevation } from '@blueprintjs/core';
import { RouteComponentProps, Redirect } from 'react-router';
import { ChangePasswordLink } from '../../graphql/mutation/PasswordReset';
import InputField from '../Form/Field/InputField';

interface MyFormValues {
  email: string;
  password: string;
}

type Props = RouteComponentProps<{ secret: string }>;

const ResetLink = (props: Props) => {
  const [changePasswordLink, response] = ChangePasswordLink();
  const [actions, setActions] = useState<FormikHelpers<MyFormValues>>();
  if (actions) {
    if (response && response.error) {
      response.error.graphQLErrors.map(error => {
        if (error.path) {
          return error.path.map(path => {
            return actions.setFieldError(path.toString(), error.message);
          });
        } else {
          return actions.setFieldError('password', error.message);
        }
      });
    }
  }
  const handleSubmit = (
    values: MyFormValues,
    actions: FormikHelpers<MyFormValues>
  ) => {
    changePasswordLink({
      variables: { ...values, secret: props.match.params.secret }
    });
    setActions(actions);
  };

  const { loading, data } = response;

  if (data?.changePasswordLink?.valueOf())
    return <Redirect to={'/PasswordReset/success'} />;

  const second = () => {
    return (
      <Card elevation={Elevation.TWO} style={{ minWidth: '25%' }}>
        <h2>Password Reset</h2>
        <Form>
          <InputField name="email" type="email" placeholder="Email" />
          <InputField name="password" type="password" placeholder="Password" />
          <Button
            type="submit"
            className="bp3-fill"
            intent="success"
            style={{ marginTop: 10 }}
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      </Card>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        render={second}
      />
    </div>
  );
};

export default ResetLink;
