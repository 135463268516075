import gql from 'graphql-tag';
import { InformationRequest } from '../../models/models';
import { FULL_USER_FRAGMENT } from '../Fragment/User';
import { useQuery } from '@apollo/react-hooks';

export interface InformationRequestQueryArgs {
  id: string;
}

export interface InformationRequestQueryResponse {
  informationRequest: InformationRequest;
}

export const INFORMATION_REQUEST_QUERY = gql`
  query InformationRequest($id: ID!) {
    informationRequest(id: $id) {
      id
      requestingUserId
      projectTypeId
      projectNumber
      requestLevelId
      modelNumber
      departmentId
      informationRequestStatusId
      title
      assignedUserId
      requestedDate
      createdAt
      closedDate
      requestLevel {
        id
        name
      }
      projectType {
        id
        name
      }
      informationRequestStatus {
        id
        name
      }
      assignedUser {
        ...FullUser
      }
      department {
        id
        name
      }
      requestingUser {
        ...FullUser
      }
      informationRequestHasMessage {
        message {
          id
          userId
          user {
            ...FullUser
          }
          createdAt
          body
        }
      }
    }
  }
  ${FULL_USER_FRAGMENT}
`;

export const InformationRequestQuery = (
  variables: InformationRequestQueryArgs
) =>
  useQuery<InformationRequestQueryResponse, InformationRequestQueryArgs>(
    INFORMATION_REQUEST_QUERY,
    { variables }
  );

export interface AllInformationRequestQueryResponse {
  informationRequests: InformationRequest[];
}

export const ALL_INFORMATION_REQUEST_QUERY = gql`
  query InformationRequestQuery {
    informationRequests {
      id
      requestingUserId
      projectTypeId
      projectNumber
      requestLevelId
      modelNumber
      departmentId
      informationRequestStatusId
      title
      assignedUserId
      requestedDate
      createdAt
      closedDate
      requestLevel {
        id
        name
      }
      projectType {
        id
        name
      }
      informationRequestStatus {
        id
        name
      }
      assignedUser {
        ...FullUser
      }
      department {
        id
        name
      }
      requestingUser {
        ...FullUser
      }
      informationRequestHasMessage {
        message {
          id
          userId
          user {
            ...FullUser
          }
          createdAt
          body
        }
      }
    }
  }
  ${FULL_USER_FRAGMENT}
`;

export const AllInformationRequestQuery = () =>
  useQuery<AllInformationRequestQueryResponse>(ALL_INFORMATION_REQUEST_QUERY);
