import { Select, ItemRenderer } from '@blueprintjs/select';
import { PartTrackerStatus } from '../../models/models';
import { useQuery } from '@apollo/react-hooks';
import { Button, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
import { PART_TRACKER_STATUSES } from '../../graphql/query/PartTracker';
interface PartTrackerStatusArray {
  partTrackerStatuses: PartTrackerStatus[];
}

const PartTrackerStatusSelectType = Select.ofType<PartTrackerStatus>();

const itemRenderer: ItemRenderer<PartTrackerStatus> = (
  { id, name },
  { handleClick, index }
) => (
  <MenuItem
    key={id}
    onClick={handleClick}
    tabIndex={index}
    style={{
      fontSize: 16,
      fontWeight: 'bolder',
      margin: 2,
      textAlign: 'center'
    }}
    text={name}
  />
);
interface MyProps {
  selectedId: number;
  onItemSelect: (
    item: PartTrackerStatus,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void;
}

const PartTrackerStatusSelect = (props: MyProps) => {
  const { loading, error, data } = useQuery<PartTrackerStatusArray>(
    PART_TRACKER_STATUSES
  );
  if (loading) {
    return <p>loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  if (data == null || data.partTrackerStatuses == null) {
    return <p>is null</p>;
  }
  const find = data.partTrackerStatuses.find(a => a.id === props.selectedId);
  let text = 'NONE';
  if (find) {
    text = find.name;
  }
  return (
    <PartTrackerStatusSelectType
      items={data.partTrackerStatuses}
      itemRenderer={itemRenderer}
      onItemSelect={props.onItemSelect}
      noResults={<MenuItem disabled={true} text="No results." />}
    >
      <Button text={text} rightIcon="double-caret-vertical" />
    </PartTrackerStatusSelectType>
  );
};

export default PartTrackerStatusSelect;
