import * as Survey from "survey-react";
import "survey-react/survey.css"
import * as React from "react";
import { generateSurveyModel } from "./processSurvey";
import { GetSurveyTemplate } from "../graphql/query/survey";
import { Card } from "@blueprintjs/core";
import { RouteComponentProps } from "react-router";
import Loading from "../components/Loading";
import CreateSurveyResponseForm from "./CreateSurveyResponseForm";
const onComplete = (survey: any, options: any) => {
    //Write survey results into database
    console.log("Survey results: " + JSON.stringify(survey.data));
   }
export const ViewSurveyTemplate = (props: RouteComponentProps<{ id: string }>) => {
    const { id } = props.match.params;
    const { loading, error, data } = GetSurveyTemplate({ id });
    if (loading) {
        return <Loading />;
    }
    if (!data) {
        return <p>dataNotFound</p>;
    }
    if (error) {
        return <p>{error.message}</p>;
    }

    return (
    <div>
        <Card title="Survey Preview">
            <h2>Survey Preview</h2>
            <Survey.Survey model={generateSurveyModel(data.surveyTemplate)} onComplete={onComplete}  />
        </Card>
        <Card>
            <CreateSurveyResponseForm templateId={Number.parseInt(id)} />
        </Card>
    </div>);
}