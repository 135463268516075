import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { FormGroup } from '@blueprintjs/core';
import UserSelectWithUserTitle from '../Select/User/UserSelectWithUserTitle';

interface MyProps {
  label: string;
  name: string;
  userTitle: string;
}

const UsersTitleFilterSelect = (
  { field, form }: FieldProps,
  userTitle: string
) => {
  return (
    <UserSelectWithUserTitle
      selectedId={field.value}
      onItemSelect={(item, e) => form.setFieldValue(field.name, item.id)}
      args={{ userTitle }}
    />
  );
};
const UserSelectWithUserTitleField = (props: MyProps) => (
  <FormGroup label={props.label} className="bp3-fill">
    <Field
      name={props.name}
      render={(fieldProps: FieldProps) =>
        UsersTitleFilterSelect(fieldProps, props.userTitle)
      }
    />
  </FormGroup>
);

export default UserSelectWithUserTitleField;
