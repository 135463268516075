import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';

export const charCol: Partial<ColDef> = {
  sortable: true,
  filter: true,
  enableRowGroup: true,
  enablePivot: true
};
export const intCol: Partial<ColDef> = {
  sortable: true,
  filter: true,
  enableRowGroup: true,
  enablePivot: true
};
export const notesCol: Partial<ColDef> = {
  sortable: true,
  filter: true,
  enableRowGroup: true,
  enablePivot: true
};
export const strCol: Partial<ColDef> = {
  sortable: true,
  filter: true,
  enableRowGroup: true,
  enablePivot: true
};
export const firstNameCol: Partial<ColDef> = {
  ...strCol
};
export const lastNameCol: Partial<ColDef> = {
  ...strCol
};
export const idCol: Partial<ColDef> = {
  sortable: true,
  filter: true,
  enableRowGroup: true,
  enablePivot: true
};
export const moneyCol: Partial<ColDef> = {
  sortable: true,
  filter: true,
  enableRowGroup: true,
  enablePivot: true
};
export const quantityCol: Partial<ColDef> = {
  sortable: true,
  filter: true,
  enableRowGroup: true,
  enablePivot: true
};
export const dateCol: Partial<ColDef> = {
  filter: 'agDateColumnFilter',
  sortable: true,
  valueFormatter: function(params: ValueFormatterParams) {
    if (!params.value) {
      return '';
    }
    const mom = moment(params.value);
    if (!mom.isValid()) {
      return '';
    }
    return mom.format('L');
  },
  filterParams: {
    comparator: function(filterLocalDateAtMidnight: Date, cellValue: string) {
      const filter = moment(filterLocalDateAtMidnight);
      const og = moment(cellValue, 'YYYY-MM-DD');
      if (filter.isSame(og, 'day')) return 0;
      if (filter.isAfter(og, 'day')) {
        return -1;
      } else {
        return 1;
      }
    }
  },
  comparator: function(filterLocalDateAtMidnight: Date, cellValue: string) {
    const filter = moment(filterLocalDateAtMidnight);
    const og = moment(cellValue, 'YYYY-MM-DD');
    if (filter.isSame(og, 'day')) return 0;
    if (filter.isAfter(og, 'day')) {
      return -1;
    } else {
      return 1;
    }
  }
};
