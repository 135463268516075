import gql from 'graphql-tag';
import { MessagePostInput, Message } from '../../models/models';
import { useMutation } from '@apollo/react-hooks';
import { FULL_MESSAGE_FRAGMENT } from '../Fragment/Message';
import {
  INFORMATION_REQUEST_QUERY,
  InformationRequestQueryResponse,
  InformationRequestQueryArgs
} from '../query/InformationRequest';

export interface AddMessageToInformationRequestResult {
  addMessageToInformationRequest: Message;
}

export interface AddMessageToInformationRequestArgs {
  informationRequestId: number;
  message: MessagePostInput;
}

export const ADD_MESSAGE_TO_INFORMATION_REQUEST_MUTATION = gql`
  mutation addMessageToInformationRequest(
    $informationRequestId: Int!
    $message: MessagePostInput!
  ) {
    addMessageToInformationRequest(
      informationRequestId: $informationRequestId
      message: $message
    ) {
      ...FullMessage
    }
  }
  ${FULL_MESSAGE_FRAGMENT}
`;

export const AddMessageToInformationRequestMutation = (
  informationRequestId: string
) =>
  useMutation<
    AddMessageToInformationRequestResult,
    AddMessageToInformationRequestArgs
  >(ADD_MESSAGE_TO_INFORMATION_REQUEST_MUTATION, {
    update(cache, mutationResult) {
      if (
        mutationResult.data &&
        mutationResult.data.addMessageToInformationRequest
      ) {
        const cacheData = cache.readQuery<
          InformationRequestQueryResponse,
          InformationRequestQueryArgs
        >({
          query: INFORMATION_REQUEST_QUERY,
          variables: { id: informationRequestId }
        });
        if (cacheData && cacheData.informationRequest) {
          cache.writeQuery<
            InformationRequestQueryResponse,
            InformationRequestQueryArgs
          >({
            query: INFORMATION_REQUEST_QUERY,
            variables: { id: informationRequestId },
            data: {
              informationRequest: {
                ...cacheData.informationRequest,
                informationRequestHasMessage: [
                  ...cacheData.informationRequest.informationRequestHasMessage,
                  {
                    message: mutationResult.data.addMessageToInformationRequest,
                    __typename: 'InformationRequestHasMessage'
                  }
                ]
              }
            }
          });
        }
      }
    }
  });
