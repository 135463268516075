import * as Survey from "survey-react";
import "survey-react/survey.css"
import * as React from "react";
import { generateSurveyModel } from "./processSurvey";
import { SurveyResponseUriQuery } from "../graphql/query/survey";
import { RouteComponentProps } from "react-router";
import Loading from "../components/Loading";
import ErrorComponent from "../components/ErrorComponent";
import { SurveyResponseHasSurveyFieldInput } from "../models/models";
import { RespondSurveyResponseMutation, RespondSurveyResponseArgs } from "../graphql/mutation/survey";
import { H3, Icon } from "@blueprintjs/core";

const CompleteText = (<div style={{ margin: 30, display: "flex", flex: 1, flexGrow: 1, flexDirection: "row", justifyContent: "center"}}>
  <H3 style={{textAlign: "center"}}>
    Thank you for completing the survey! 
    <Icon icon="thumbs-up" intent="success" style={{ verticalAlign: "top" }} iconSize={25} />
  </H3>
  </div>);

export const RespondSurveyForm = (props: RouteComponentProps<{ uri: string }>) => {
  const { uri } = props.match.params;
  const { loading, error, data } = SurveyResponseUriQuery({ uri });
  const [respondSurveyResponseMutation, mutationData] = RespondSurveyResponseMutation();

  const onComplete = (survey: Survey.SurveyModel, options: any) => {
    let surveyResponseHasSurveyField: SurveyResponseHasSurveyFieldInput[] = Object.keys(survey.data).map(key => {
      return { surveyFieldHasSurveySectionId: Number.parseInt(key), data: survey.data[key].toString() }
    });
    let variables: RespondSurveyResponseArgs = { surveyResponseRespondInput: { uri, surveyResponseHasSurveyField } };
    respondSurveyResponseMutation({ variables });
  }
  if (loading) {
    return <Loading />;
  }
  if (error) {
    if (error.graphQLErrors[0].extensions!.data.type === "SURVEY_RESPONSE_RECORDED") {
      return CompleteText;
    } else {
      return <ErrorComponent errorText={error.message} />;
    }
  }
  if (mutationData.called) {
    if (mutationData.loading) {
      return <Loading />;
    }
    if (mutationData.error) {
      return <ErrorComponent />
    }
    if (mutationData.data) {
      return CompleteText;
    }
  }
  var model = generateSurveyModel(data!.surveyResponseUri!.surveyTemplate);
  return <Survey.Survey model={model} onComplete={onComplete} />
}