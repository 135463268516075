import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { PartTracker } from '../../../models/models';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {
  ColGroupDef,
  SideBarDef,
  GridReadyEvent,
  GridApi,
  ColumnApi
} from 'ag-grid-community';

import 'ag-grid-enterprise';
import { getPartTracker } from '../../../Table/PartTrackerColDef';
import { PART_TRACKER_QUERY } from '../../../graphql/query/PartTracker';
import { Button, ButtonGroup } from '@blueprintjs/core';
import Loading from '../../Loading';
import ErrorComponent from '../../ErrorComponent';
interface PartTrackerArray {
  partTrackers: PartTracker[];
}
const colGroup: ColGroupDef[] = [getPartTracker('')];
const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel'
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel'
    }
  ]
};

function App() {
   // eslint-disable-next-line
  const [gridApi, setGridApi] = useState<GridApi>();
   // eslint-disable-next-line
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    params.api.setFilterModel({"status.name":{ type: "set", values: ["Open"]}});
  };

  const [graphqlData, setGraphqlData] = useState<PartTracker[]>();
  const [ref, setRef] = useState<boolean>(true);
  const { loading, error, data, refetch } = useQuery<PartTrackerArray>(
    PART_TRACKER_QUERY
  );
  const refetchQuery = () => {
    setRef(true);
    refetch();
  };
  if (loading) {
    return <Loading />;
  }
  if (!data) {
    return <ErrorComponent />;
  }
  if (error) {
    return  <ErrorComponent errorText={error.message} />;
  }
  if (ref) {
    setRef(false);
    setGraphqlData(data.partTrackers);
    setTimeout(() => refetchQuery(), 300000);
  }

  return (
      <div
        style={{ height: '100%' }}
        className="ag-theme-balham"
      >
        <AgGridReact
          columnDefs={colGroup}
          enableRangeSelection={true}
          sideBar={sideBar}
          enableCharts={true}
          floatingFilter={true}
          rememberGroupStateWhenNewData={true}
          enableCellChangeFlash={true}
          animateRows={true}
          deltaRowDataMode={true}
          getRowNodeId={(data: any) => data.id}
          onGridReady={onGridReady}
          rowData={graphqlData}
        ></AgGridReact>
      </div>
  );
}

export default App;
