import gql from 'graphql-tag';
import { RequestLevel } from '../../models/models';
import { useQuery } from '@apollo/react-hooks';

interface RequestLevelsQueryResults {
  requestLevels: RequestLevel[];
}

export const REQUEST_LEVELS_QUERY = gql`
  query RequestLevel {
    requestLevels {
      id
      name
    }
  }
`;

export const RequestLevelQuery = () =>
  useQuery<RequestLevelsQueryResults>(REQUEST_LEVELS_QUERY);
