import gql from 'graphql-tag';
import { FULL_INFORMATION_REQUEST_FRAGMENT } from '../Fragment/InformationRequest';
import {
  InformationRequest,
  InformationRequestCreateInput,
  MessagePostInput
} from '../../models/models';
import { useMutation } from '@apollo/react-hooks';

export interface CreateInformationRequestResult {
  createInformationRequest: InformationRequest;
}
export interface CreateInformationRequestArgs {
  informationRequest: InformationRequestCreateInput;
  message: MessagePostInput;
}
export const CREATE_INFORMATION_REQUEST = gql`
  mutation createInformationRequest(
    $informationRequest: InformationRequestCreateInput!
    $message: MessagePostInput!
  ) {
    createInformationRequest(
      informationRequest: $informationRequest
      message: $message
    ) {
      ...FullInformationRequest
      informationRequestHasMessage {
        message {
          id
          userId
          user {
            ...FullUser
          }
          createdAt
          body
        }
      }
    }
  }
  ${FULL_INFORMATION_REQUEST_FRAGMENT}
`;

export const CreateInformationRequestMutation = () =>
  useMutation<CreateInformationRequestResult, CreateInformationRequestArgs>(
    CREATE_INFORMATION_REQUEST
  );

export interface UpdateInformationRequestResult {
  updateInformationRequest: InformationRequest;
}
export interface UpdateInformationRequestArgs {
  informationRequest: InformationRequestCreateInput;
}
export const UPDATE_INFORMATION_REQUEST = gql`
  mutation updateInformationRequest(
    $informationRequest: InformationRequestUpdateInput!
  ) {
    updateInformationRequest(informationRequest: $informationRequest) {
      ...FullInformationRequest
      informationRequestHasMessage {
        message {
          id
          userId
          user {
            ...FullUser
          }
          createdAt
          body
        }
      }
    }
  }
  ${FULL_INFORMATION_REQUEST_FRAGMENT}
`;

export const UpdateInformationRequestMutation = () =>
  useMutation<UpdateInformationRequestResult, UpdateInformationRequestArgs>(
    UPDATE_INFORMATION_REQUEST
  );
