import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  Decimal: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
};

export type Acknowledgement = {
  __typename?: 'Acknowledgement';
  accountingReviewDate?: Maybe<Scalars['DateTime']>;
  acknowledgementDate?: Maybe<Scalars['DateTime']>;
  engineeringReleaseDate?: Maybe<Scalars['DateTime']>;
  job?: Maybe<Job>;
  jobNumber: Scalars['Int'];
  notes: Scalars['String'];
  productionReviewDate?: Maybe<Scalars['DateTime']>;
};

export type AdminUpdateUserInput = {
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  userTitleId?: Maybe<Scalars['Int']>;
  notes: Scalars['String'];
  deleted?: Maybe<Scalars['String']>;
};

export type AssemblyTeam = {
  __typename?: 'AssemblyTeam';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Billing = {
  __typename?: 'Billing';
  id: Scalars['Int'];
  job?: Maybe<Job>;
  jobNumber: Scalars['Int'];
  notes: Scalars['String'];
};

export enum ComparisonGraph {
  contains = 'contains',
  endsWith = 'endsWith',
  equal = 'equal',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  notIn = 'notIn',
  in = 'in',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
  like = 'like',
  startsWith = 'startsWith'
}

export enum Connector {
  AND = 'AND',
  OR = 'OR',
  and = 'and',
  or = 'or'
}

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['Int'];
  jobs: Array<Job>;
  name: Scalars['String'];
};


export type CustomerJobsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};




export type DeliveryType = {
  __typename?: 'DeliveryType';
  id: Scalars['Int'];
  isShipping: Scalars['String'];
  name: Scalars['String'];
};

export type DeliveryTypeCreateInput = {
  name: Scalars['String'];
  isShipping: Scalars['String'];
};

export type DeliveryTypeUpdateInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  isShipping: Scalars['String'];
};

export type Department = {
  __typename?: 'Department';
  departmentUserList: Array<DepartmentUserList>;
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type DepartmentDepartmentUserListArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type DepartmentUserInput = {
  departmentsId: Scalars['Int'];
  usersId: Scalars['Int'];
};

export type DepartmentUserList = {
  __typename?: 'DepartmentUserList';
  department?: Maybe<Department>;
  departmentsId: Scalars['Int'];
  user?: Maybe<User>;
  usersId: Scalars['Int'];
};

export type Engineering = {
  __typename?: 'Engineering';
  assignedDate?: Maybe<Scalars['DateTime']>;
  bomActualDate?: Maybe<Scalars['DateTime']>;
  bomMilestoneDate?: Maybe<Scalars['DateTime']>;
  compDesPlanDate?: Maybe<Scalars['DateTime']>;
  designRepresentative?: Maybe<User>;
  drawingApprovedDate?: Maybe<Scalars['DateTime']>;
  drawingDueDate?: Maybe<Scalars['DateTime']>;
  drawingResubmitAmount?: Maybe<Scalars['Int']>;
  drawingResubmitMilestoneDate?: Maybe<Scalars['DateTime']>;
  drawingResubmittedDate?: Maybe<Scalars['DateTime']>;
  drawingReturnedDate?: Maybe<Scalars['DateTime']>;
  drawingSubmittedDate?: Maybe<Scalars['DateTime']>;
  duct: Scalars['String'];
  ductApprovedDate?: Maybe<Scalars['DateTime']>;
  ductReleasedDate?: Maybe<Scalars['DateTime']>;
  job?: Maybe<Job>;
  jobNumber: Scalars['Int'];
  longLead: Scalars['String'];
  longLeadActualDate?: Maybe<Scalars['DateTime']>;
  longLeadMilestoneDate?: Maybe<Scalars['DateTime']>;
  notes: Scalars['String'];
  programCompletionDate?: Maybe<Scalars['DateTime']>;
  programMilestoneDate?: Maybe<Scalars['DateTime']>;
  programRequired: Scalars['String'];
  releaseToProductionDate?: Maybe<Scalars['DateTime']>;
  stack: Scalars['String'];
  stackApprovedDate?: Maybe<Scalars['DateTime']>;
  stackReleasedDate?: Maybe<Scalars['DateTime']>;
};

export type EquipmentModelSale = {
  __typename?: 'EquipmentModelSale';
  job?: Maybe<Sale>;
  jobNumber: Scalars['Int'];
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Decimal']>;
};

export type InformationRequest = {
  __typename?: 'InformationRequest';
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars['Int']>;
  closedDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  informationRequestHasMessage: Array<InformationRequestHasMessage>;
  informationRequestHasMessageConnection?: Maybe<InformationRequestHasMessageGraphConnection>;
  informationRequestStatus?: Maybe<InformationRequestStatus>;
  informationRequestStatusId?: Maybe<Scalars['Int']>;
  informationRequestUserList: Array<InformationRequestUserList>;
  modelNumber?: Maybe<Scalars['String']>;
  projectNumber?: Maybe<Scalars['String']>;
  projectType?: Maybe<ProjectType>;
  projectTypeId?: Maybe<Scalars['Int']>;
  requestedDate?: Maybe<Scalars['DateTime']>;
  requestingUser?: Maybe<User>;
  requestingUserId?: Maybe<Scalars['Int']>;
  requestLevel?: Maybe<RequestLevel>;
  requestLevelId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};


export type InformationRequestInformationRequestHasMessageArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type InformationRequestInformationRequestHasMessageConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type InformationRequestInformationRequestUserListArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type InformationRequestCreateInput = {
  requestingUserId?: Maybe<Scalars['Int']>;
  projectTypeId?: Maybe<Scalars['Int']>;
  projectNumber?: Maybe<Scalars['String']>;
  requestLevelId?: Maybe<Scalars['Int']>;
  informationRequestStatusId?: Maybe<Scalars['Int']>;
  modelNumber?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  assignedUserId?: Maybe<Scalars['Int']>;
  requestedDate?: Maybe<Scalars['DateTime']>;
};

export type InformationRequestHasMessage = {
  __typename?: 'InformationRequestHasMessage';
  informationRequest?: Maybe<InformationRequest>;
  message?: Maybe<Message>;
};

/** A connection from an object to a list of objects of type `InformationRequestHasMessageGraph`. */
export type InformationRequestHasMessageGraphConnection = {
  __typename?: 'InformationRequestHasMessageGraphConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<InformationRequestHasMessageGraphEdge>>>;
  /**
   * A list of all of the objects returned in the connection. This is a convenience
   * field provided for quickly exploring the API; rather than querying for "{
   * edges { node } }" when no edge data is needed, this field can be used instead.
   * Note that when clients like Relay need to fetch the "cursor" field on the edge
   * to enable efficient pagination, this shortcut cannot be used, and the full "{
   * edges { node } } " version should be used instead.
   */
  items?: Maybe<Array<Maybe<InformationRequestHasMessage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * A count of the total number of objects in this connection, ignoring
   * pagination. This allows a client to fetch the first five objects by passing
   * "5" as the argument to `first`, then fetch the total count so it could display
   * "5 of 83", for example. In cases where we employ infinite scrolling or don't
   * have an exact count of entries, this field will return `null`.
   */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `InformationRequestHasMessageGraph`. */
export type InformationRequestHasMessageGraphEdge = {
  __typename?: 'InformationRequestHasMessageGraphEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<InformationRequestHasMessage>;
};

export type InformationRequestStatus = {
  __typename?: 'InformationRequestStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InformationRequestUpdateInput = {
  id: Scalars['Int'];
  requestingUserId?: Maybe<Scalars['Int']>;
  projectTypeId?: Maybe<Scalars['Int']>;
  projectNumber?: Maybe<Scalars['String']>;
  requestLevelId?: Maybe<Scalars['Int']>;
  modelNumber?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  informationRequestStatusId?: Maybe<Scalars['Int']>;
  assignedUserId?: Maybe<Scalars['Int']>;
  requestedDate?: Maybe<Scalars['DateTime']>;
};

export type InformationRequestUserList = {
  __typename?: 'InformationRequestUserList';
  informationRequest?: Maybe<InformationRequest>;
  informationRequestId: Scalars['Int'];
  user?: Maybe<User>;
  usersId: Scalars['Int'];
};

export type InformationRequestUserListInput = {
  usersId: Scalars['Int'];
  informationRequestId: Scalars['Int'];
};

export type InventoryPurchasing = {
  __typename?: 'InventoryPurchasing';
  bomOrderedDate?: Maybe<Scalars['DateTime']>;
  ductOrderedDate?: Maybe<Scalars['DateTime']>;
  inventoryPulledDate?: Maybe<Scalars['DateTime']>;
  job?: Maybe<Job>;
  jobNumber: Scalars['Int'];
  longLeadsOrderedDate?: Maybe<Scalars['DateTime']>;
  notes: Scalars['String'];
  stackOrderedDate?: Maybe<Scalars['DateTime']>;
};

export type Job = {
  __typename?: 'Job';
  acknowledgement?: Maybe<Acknowledgement>;
  billings: Array<Billing>;
  crating: Scalars['String'];
  customer?: Maybe<Customer>;
  deleted: Scalars['String'];
  engineering?: Maybe<Engineering>;
  expedited: Scalars['String'];
  fatSat: Scalars['String'];
  inventoryPurchasing?: Maybe<InventoryPurchasing>;
  jobState?: Maybe<JobState>;
  lines: Array<Line>;
  notes: Scalars['String'];
  number: Scalars['Int'];
  projectManager?: Maybe<User>;
  quotedLeadTime?: Maybe<Scalars['Int']>;
  sale?: Maybe<Sale>;
  thirdPartyCertification: Scalars['String'];
};


export type JobBillingsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type JobLinesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type JobState = {
  __typename?: 'JobState';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Line = {
  __typename?: 'Line';
  actualPercentage?: Maybe<Scalars['Int']>;
  beginningMonthPercentage?: Maybe<Scalars['Int']>;
  deleted: Scalars['String'];
  endingMonthPercentage?: Maybe<Scalars['Int']>;
  equipmentModelName: Scalars['String'];
  equipmentModelQuantity?: Maybe<Scalars['Int']>;
  equipmentModelValue?: Maybe<Scalars['Decimal']>;
  job?: Maybe<Job>;
  jobNumber: Scalars['Int'];
  lineNumber: Scalars['Int'];
  linePromisedShipDate?: Maybe<Scalars['DateTime']>;
  lineState?: Maybe<LineState>;
  notes: Scalars['String'];
};

export type LineState = {
  __typename?: 'LineState';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

export type MessagePostInput = {
  body: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addDepartmentUser?: Maybe<DepartmentUserList>;
  addMessageToInformationRequest?: Maybe<Message>;
  addUserToInformationRequestCC?: Maybe<InformationRequestUserList>;
  addUserToPartTrackerCC?: Maybe<PartsTrackerUserList>;
  adminUpdateUser?: Maybe<User>;
  changePassword?: Maybe<Scalars['Boolean']>;
  changePasswordLink?: Maybe<Scalars['Boolean']>;
  createDeliveryType?: Maybe<DeliveryType>;
  createInformationRequest?: Maybe<InformationRequest>;
  createPartTracker?: Maybe<PartTracker>;
  createSurveyResponse?: Maybe<SurveyResponse>;
  login?: Maybe<Scalars['String']>;
  removeDepartmentUser?: Maybe<Scalars['Boolean']>;
  removeUserFromInformationRequestCC?: Maybe<Scalars['Boolean']>;
  removeUserFromPartTrackerCC?: Maybe<Scalars['Boolean']>;
  requestPasswordReset?: Maybe<Scalars['Boolean']>;
  respondSurveyResponse?: Maybe<SurveyResponse>;
  signupUser?: Maybe<User>;
  signupUserResendEmail?: Maybe<Scalars['Boolean']>;
  updateDeliveryType?: Maybe<DeliveryType>;
  updateInformationRequest?: Maybe<InformationRequest>;
  updatePartTracker?: Maybe<PartTracker>;
  updateSurveyResponse?: Maybe<SurveyResponse>;
};


export type MutationAddDepartmentUserArgs = {
  departmentUser: DepartmentUserInput;
};


export type MutationAddMessageToInformationRequestArgs = {
  informationRequestId: Scalars['Int'];
  message: MessagePostInput;
};


export type MutationAddUserToInformationRequestCcArgs = {
  informationRequestUserList: InformationRequestUserListInput;
};


export type MutationAddUserToPartTrackerCcArgs = {
  partTrackerUserList: PartTrackerUserListInput;
};


export type MutationAdminUpdateUserArgs = {
  user: AdminUpdateUserInput;
};


export type MutationChangePasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationChangePasswordLinkArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  secret: Scalars['String'];
};


export type MutationCreateDeliveryTypeArgs = {
  deliveryType: DeliveryTypeCreateInput;
};


export type MutationCreateInformationRequestArgs = {
  informationRequest: InformationRequestCreateInput;
  message?: Maybe<MessagePostInput>;
};


export type MutationCreatePartTrackerArgs = {
  partTracker: PartTrackerCreateInput;
};


export type MutationCreateSurveyResponseArgs = {
  surveyResponseCreateInput: SurveyResponseCreateInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRemoveDepartmentUserArgs = {
  departmentUser: DepartmentUserInput;
};


export type MutationRemoveUserFromInformationRequestCcArgs = {
  informationRequestUserList: InformationRequestUserListInput;
};


export type MutationRemoveUserFromPartTrackerCcArgs = {
  partTrackerUserList: PartTrackerUserListInput;
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationRespondSurveyResponseArgs = {
  surveyResponseRespondInput: SurveyResponseRespondInput;
};


export type MutationSignupUserArgs = {
  user: SignupUserInput;
};


export type MutationSignupUserResendEmailArgs = {
  email: Scalars['String'];
};


export type MutationUpdateDeliveryTypeArgs = {
  deliveryType: DeliveryTypeUpdateInput;
};


export type MutationUpdateInformationRequestArgs = {
  informationRequest: InformationRequestUpdateInput;
};


export type MutationUpdatePartTrackerArgs = {
  partTracker: PartTrackerUpdateInput;
};


export type MutationUpdateSurveyResponseArgs = {
  surveyResponseUpdateInput: SurveyResponseUpdateInput;
};

export type OrderByGraph = {
  path: Scalars['String'];
  descending?: Maybe<Scalars['Boolean']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PartsTrackerUserList = {
  __typename?: 'PartsTrackerUserList';
  partsTrackerId: Scalars['Int'];
  partTracker?: Maybe<PartTracker>;
  user?: Maybe<User>;
  usersId: Scalars['Int'];
};

export type PartTracker = {
  __typename?: 'PartTracker';
  assemblyTeam?: Maybe<AssemblyTeam>;
  assemblyTeamId?: Maybe<Scalars['Int']>;
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['Int']>;
  createdDate: Scalars['DateTime'];
  deliveryDate?: Maybe<Scalars['DateTime']>;
  deliveryNotes?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<DeliveryType>;
  deliveryTypeId?: Maybe<Scalars['Int']>;
  department?: Maybe<Department>;
  departmentId: Scalars['Int'];
  designRep?: Maybe<User>;
  designRepId?: Maybe<Scalars['Int']>;
  ecoNotes?: Maybe<Scalars['String']>;
  ecoNumber?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  jobNumber: Scalars['String'];
  lastModifiedByUser?: Maybe<User>;
  lastModifiedByUserId?: Maybe<Scalars['Int']>;
  lineNumber: Scalars['String'];
  manPartNumber?: Maybe<Scalars['String']>;
  partName?: Maybe<Scalars['String']>;
  partsTrackerStatusId: Scalars['Int'];
  partsTrackerUserList: Array<PartsTrackerUserList>;
  poDate?: Maybe<Scalars['DateTime']>;
  poNumber?: Maybe<Scalars['String']>;
  poRecievedDate?: Maybe<Scalars['DateTime']>;
  projectManager?: Maybe<User>;
  projectManagerId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  reasonNotes?: Maybe<Scalars['String']>;
  recNumber?: Maybe<Scalars['String']>;
  requestedDate?: Maybe<Scalars['DateTime']>;
  sasPartNumber?: Maybe<Scalars['String']>;
  status?: Maybe<PartTrackerStatus>;
  trackingNumber?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};


export type PartTrackerPartsTrackerUserListArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PartTrackerCreateInput = {
  jobNumber: Scalars['String'];
  lineNumber: Scalars['String'];
  /**
   * RELATES_TO: Department
   * OBJECT_NAME: department
   */
  departmentId: Scalars['Int'];
  partName?: Maybe<Scalars['String']>;
  /**
   * RELATES_TO: AssemblyTeam
   * OBJECT_NAME: assemblyTeam
   */
  assemblyTeamId?: Maybe<Scalars['Int']>;
  sasPartNumber?: Maybe<Scalars['String']>;
  manPartNumber?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  vendor?: Maybe<Scalars['String']>;
  requestedDate?: Maybe<Scalars['DateTime']>;
  ecoNumber?: Maybe<Scalars['String']>;
  ecoNotes?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reasonNotes?: Maybe<Scalars['String']>;
  /**
   * RELATES_TO: DeliveryType
   * OBJECT_NAME: deliveryType
   */
  deliveryTypeId?: Maybe<Scalars['Int']>;
  trackingNumber?: Maybe<Scalars['String']>;
  deliveryNotes?: Maybe<Scalars['String']>;
  /**
   * RELATES_TO: User
   * OBJECT_NAME: projectManager
   * QUALIFYING_RELATION: userTitle = Project Manager
   */
  projectManagerId?: Maybe<Scalars['Int']>;
  /**
   * RELATES_TO: User
   * OBJECT_NAME: designRep
   * QUALIFYING_RELATION: userTitle = Design Rep
   */
  designRepId?: Maybe<Scalars['Int']>;
};

export type PartTrackerStatus = {
  __typename?: 'PartTrackerStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** parts! */
export type PartTrackerUpdateInput = {
  id: Scalars['Int'];
  /**
   * RELATES_TO: PartTrackerStatus
   * OBJECT_NAME: status
   */
  partsTrackerStatusId: Scalars['Int'];
  jobNumber: Scalars['String'];
  lineNumber: Scalars['String'];
  /**
   * RELATES_TO: Department
   * OBJECT_NAME: department
   */
  departmentId: Scalars['Int'];
  partName?: Maybe<Scalars['String']>;
  recNumber?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  poDate?: Maybe<Scalars['DateTime']>;
  /**
   * RELATES_TO: AssemblyTeam
   * OBJECT_NAME: assemblyTeam
   */
  assemblyTeamId?: Maybe<Scalars['Int']>;
  sasPartNumber?: Maybe<Scalars['String']>;
  manPartNumber?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  vendor?: Maybe<Scalars['String']>;
  poRecievedDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
  requestedDate?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  ecoNumber?: Maybe<Scalars['String']>;
  ecoNotes?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reasonNotes?: Maybe<Scalars['String']>;
  /**
   * RELATES_TO: DeliveryType
   * OBJECT_NAME: deliveryType
   */
  deliveryTypeId?: Maybe<Scalars['Int']>;
  trackingNumber?: Maybe<Scalars['String']>;
  deliveryNotes?: Maybe<Scalars['String']>;
  /**
   * RELATES_TO: User
   * OBJECT_NAME: projectManager
   * QUALIFYING_RELATION: userTitle = Project Manager
   */
  projectManagerId?: Maybe<Scalars['Int']>;
  /**
   * RELATES_TO: User
   * OBJECT_NAME: designRep
   * QUALIFYING_RELATION: userTitle = Design Rep
   */
  designRepId?: Maybe<Scalars['Int']>;
};

export type PartTrackerUserListInput = {
  usersId: Scalars['Int'];
  partsTrackerId: Scalars['Int'];
};

export type ProjectType = {
  __typename?: 'ProjectType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  acknowledgement: Acknowledgement;
  acknowledgements: Array<Acknowledgement>;
  assemblyTeam: AssemblyTeam;
  assemblyTeams: Array<AssemblyTeam>;
  billing: Billing;
  billings: Array<Billing>;
  customer: Customer;
  customers: Array<Customer>;
  deliveryType: DeliveryType;
  deliveryTypes: Array<DeliveryType>;
  department: Department;
  departments: Array<Department>;
  departmentUserList: DepartmentUserList;
  departmentUserLists: Array<DepartmentUserList>;
  engineering: Engineering;
  engineerings: Array<Engineering>;
  equipmentModelSale: EquipmentModelSale;
  equipmentModelSales: Array<EquipmentModelSale>;
  informationRequest: InformationRequest;
  informationRequests: Array<InformationRequest>;
  informationRequestStatus: InformationRequestStatus;
  informationRequestStatuses: Array<InformationRequestStatus>;
  inventoryPurchasing: InventoryPurchasing;
  inventoryPurchasings: Array<InventoryPurchasing>;
  job: Job;
  jobs: Array<Job>;
  line: Line;
  lines: Array<Line>;
  me: User;
  partTracker: PartTracker;
  partTrackers: Array<PartTracker>;
  partTrackerStatus: PartTrackerStatus;
  partTrackerStatuses: Array<PartTrackerStatus>;
  ping?: Maybe<Scalars['String']>;
  projectType: ProjectType;
  projectTypes: Array<ProjectType>;
  requestLevel: RequestLevel;
  requestLevels: Array<RequestLevel>;
  sale: Sale;
  sales: Array<Sale>;
  surveyResponse: SurveyResponse;
  surveyResponses: Array<SurveyResponse>;
  surveyResponseUri: SurveyResponse;
  surveySection: SurveySection;
  surveySections: Array<SurveySection>;
  surveyTemplate: SurveyTemplate;
  surveyTemplates: Array<SurveyTemplate>;
  user: User;
  users: Array<User>;
  userTitle: UserTitle;
  userTitles: Array<UserTitle>;
};


export type QueryAcknowledgementArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAcknowledgementsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAssemblyTeamArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAssemblyTeamsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryBillingArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryBillingsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryCustomerArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryCustomersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryTypeArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryTypesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDepartmentArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDepartmentsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDepartmentUserListArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDepartmentUserListsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryEngineeringArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryEngineeringsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryEquipmentModelSaleArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryEquipmentModelSalesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryInformationRequestArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryInformationRequestsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryInformationRequestStatusArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryInformationRequestStatusesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryInventoryPurchasingArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryInventoryPurchasingsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryJobArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryJobsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryLineArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryLinesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryMeArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPartTrackerArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPartTrackersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPartTrackerStatusArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPartTrackerStatusesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryProjectTypeArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryProjectTypesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryRequestLevelArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryRequestLevelsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySaleArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySalesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySurveyResponseArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySurveyResponsesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySurveyResponseUriArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  uri: Scalars['String'];
};


export type QuerySurveySectionArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySurveySectionsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySurveyTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySurveyTemplatesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryUserTitleArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryUserTitlesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type RequestLevel = {
  __typename?: 'RequestLevel';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Sale = {
  __typename?: 'Sale';
  equipmentModelSales: Array<EquipmentModelSale>;
  job?: Maybe<Job>;
  jobNumber: Scalars['Int'];
  promisedShipDate?: Maybe<Scalars['DateTime']>;
  purchaseOrderDate?: Maybe<Scalars['DateTime']>;
  purchaseOrderNumber: Scalars['String'];
  purchaseOrderReceivedDate?: Maybe<Scalars['DateTime']>;
  quoteNumber?: Maybe<Scalars['String']>;
  representative?: Maybe<User>;
  selectedSaleOptions: Array<SelectedSaleOption>;
};


export type SaleEquipmentModelSalesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type SaleSelectedSaleOptionsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SelectedSaleOption = {
  __typename?: 'SelectedSaleOption';
  jobNumber: Scalars['Int'];
  sale?: Maybe<Sale>;
};

export type SignupUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  userTitleId?: Maybe<Scalars['Int']>;
  notes: Scalars['String'];
};

export enum StringComparison {
  CURRENT_CULTURE = 'CURRENT_CULTURE',
  CURRENT_CULTURE_IGNORE_CASE = 'CURRENT_CULTURE_IGNORE_CASE',
  INVARIANT_CULTURE = 'INVARIANT_CULTURE',
  INVARIANT_CULTURE_IGNORE_CASE = 'INVARIANT_CULTURE_IGNORE_CASE',
  ORDINAL = 'ORDINAL',
  ORDINAL_IGNORE_CASE = 'ORDINAL_IGNORE_CASE'
}

export type SurveyDataType = {
  __typename?: 'SurveyDataType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SurveyField = {
  __typename?: 'SurveyField';
  id: Scalars['Int'];
  surveyDataType?: Maybe<SurveyDataType>;
  surveyDataTypeId: Scalars['Int'];
  title: Scalars['String'];
};

export type SurveyFieldHasSurveySection = {
  __typename?: 'SurveyFieldHasSurveySection';
  fieldNumber: Scalars['Int'];
  id: Scalars['Int'];
  surveyField?: Maybe<SurveyField>;
  surveyFieldId: Scalars['Int'];
  surveySection?: Maybe<SurveySection>;
  surveySectionId: Scalars['Int'];
};

export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  companyName: Scalars['String'];
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  hasResponded: Scalars['Int'];
  hiddenNotes: Scalars['String'];
  id: Scalars['Int'];
  respondedDate?: Maybe<Scalars['DateTimeOffset']>;
  surveyResponseHasSurveyField: Array<SurveyResponseHasSurveyField>;
  surveyTemplate?: Maybe<SurveyTemplate>;
  surveyTemplateId: Scalars['Int'];
  title: Scalars['String'];
  uri: Scalars['String'];
};


export type SurveyResponseSurveyResponseHasSurveyFieldArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SurveyResponseCreateInput = {
  surveyTemplateId: Scalars['Int'];
  title: Scalars['String'];
  hiddenNotes: Scalars['String'];
  companyName: Scalars['String'];
};

export type SurveyResponseHasSurveyField = {
  __typename?: 'SurveyResponseHasSurveyField';
  data: Scalars['String'];
  id: Scalars['Int'];
  surveyFieldHasSurveySection?: Maybe<SurveyFieldHasSurveySection>;
  surveyFieldHasSurveySectionId: Scalars['Int'];
  surveyResponse?: Maybe<SurveyResponse>;
  surveyResponseId: Scalars['Int'];
};

export type SurveyResponseHasSurveyFieldInput = {
  surveyFieldHasSurveySectionId: Scalars['Int'];
  data: Scalars['String'];
};

export type SurveyResponseRespondInput = {
  uri: Scalars['String'];
  surveyResponseHasSurveyField?: Maybe<Array<Maybe<SurveyResponseHasSurveyFieldInput>>>;
};

export type SurveyResponseUpdateInput = {
  id: Scalars['Int'];
  title: Scalars['String'];
  hiddenNotes: Scalars['String'];
  companyName: Scalars['String'];
};

export type SurveySection = {
  __typename?: 'SurveySection';
  fieldNumber: Scalars['Int'];
  id: Scalars['Int'];
  surveyFields: Array<SurveyFieldHasSurveySection>;
  surveyTemplate?: Maybe<SurveyTemplate>;
  surveyTemplateId: Scalars['Int'];
  title: Scalars['String'];
};


export type SurveySectionSurveyFieldsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  surveyResponses: Array<SurveyResponse>;
  surveySections: Array<SurveySection>;
};


export type SurveyTemplateSurveyResponsesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type SurveyTemplateSurveySectionsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>;
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  cellPhoneNumber?: Maybe<Scalars['String']>;
  deleted: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  notes: Scalars['String'];
  userTitle?: Maybe<UserTitle>;
  workPhoneNumber?: Maybe<Scalars['String']>;
};

export type UserTitle = {
  __typename?: 'UserTitle';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type WhereExpressionGraph = {
  path?: Maybe<Scalars['String']>;
  comparison?: Maybe<ComparisonGraph>;
  negate?: Maybe<Scalars['Boolean']>;
  case?: Maybe<StringComparison>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
  connector?: Maybe<Connector>;
  groupedExpressions?: Maybe<Array<Maybe<WhereExpressionGraph>>>;
};

export type CreateDeliveryTypeMutationVariables = Exact<{
  createDeliveryType: DeliveryTypeCreateInput;
}>;


export type CreateDeliveryTypeMutation = (
  { __typename?: 'Mutation' }
  & { createDeliveryType?: Maybe<(
    { __typename?: 'DeliveryType' }
    & Pick<DeliveryType, 'id' | 'name' | 'isShipping'>
  )> }
);

export type UpdateDeliveryTypeMutationVariables = Exact<{
  updateDeliveryType: DeliveryTypeUpdateInput;
}>;


export type UpdateDeliveryTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateDeliveryType?: Maybe<(
    { __typename?: 'DeliveryType' }
    & Pick<DeliveryType, 'id' | 'name' | 'isShipping'>
  )> }
);

export type AddDepartmentUserMutationVariables = Exact<{
  departmentUser: DepartmentUserInput;
}>;


export type AddDepartmentUserMutation = (
  { __typename?: 'Mutation' }
  & { addDepartmentUser?: Maybe<(
    { __typename?: 'DepartmentUserList' }
    & FullDepartmentUserListFragment
  )> }
);

export type RemoveDepartmentUserMutationVariables = Exact<{
  departmentUser: DepartmentUserInput;
}>;


export type RemoveDepartmentUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeDepartmentUser'>
);

export type DeliveryTypeQueryParamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeliveryTypeQueryParamQuery = (
  { __typename?: 'Query' }
  & { deliveryType: (
    { __typename?: 'DeliveryType' }
    & Pick<DeliveryType, 'id' | 'name' | 'isShipping'>
  ) }
);

export type DeliveryTypeQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type DeliveryTypeQueryQuery = (
  { __typename?: 'Query' }
  & { deliveryTypes: Array<(
    { __typename?: 'DeliveryType' }
    & Pick<DeliveryType, 'id' | 'name' | 'isShipping'>
  )> }
);

export type InformationRequestByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InformationRequestByIdQuery = (
  { __typename?: 'Query' }
  & { informationRequest: (
    { __typename?: 'InformationRequest' }
    & FullInformationRequestFragment
  ) }
);

export type AllInformationRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllInformationRequestsQuery = (
  { __typename?: 'Query' }
  & { informationRequests: Array<(
    { __typename?: 'InformationRequest' }
    & FullInformationRequestFragment
  )> }
);

export type FullInformationRequestFragment = (
  { __typename?: 'InformationRequest' }
  & Pick<InformationRequest, 'id' | 'requestingUserId' | 'projectTypeId' | 'projectNumber' | 'requestLevelId' | 'modelNumber' | 'departmentId' | 'informationRequestStatusId' | 'title' | 'assignedUserId' | 'requestedDate' | 'createdAt' | 'closedDate'>
  & { informationRequestUserList: Array<{ __typename: 'InformationRequestUserList' }>, requestLevel?: Maybe<(
    { __typename?: 'RequestLevel' }
    & Pick<RequestLevel, 'id' | 'name'>
  )>, projectType?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'name'>
  )>, informationRequestStatus?: Maybe<(
    { __typename?: 'InformationRequestStatus' }
    & Pick<InformationRequestStatus, 'id' | 'name'>
  )>, assignedUser?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )>, department?: Maybe<(
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  )>, requestingUser?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )>, informationRequestHasMessage: Array<(
    { __typename?: 'InformationRequestHasMessage' }
    & { message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'userId' | 'createdAt' | 'body'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & FullUserFragment
      )> }
    )> }
  )> }
);

export type FullUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'firstName' | 'lastName' | 'email' | 'id' | 'notes' | 'workPhoneNumber' | 'cellPhoneNumber' | 'deleted'>
  & { userTitle?: Maybe<(
    { __typename?: 'UserTitle' }
    & Pick<UserTitle, 'id' | 'name'>
  )> }
);

export type FullDepartmentUserListFragment = (
  { __typename?: 'DepartmentUserList' }
  & Pick<DepartmentUserList, 'departmentsId' | 'usersId'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )> }
);

export type FullDepartmentFragment = (
  { __typename?: 'Department' }
  & Pick<Department, 'id' | 'name'>
  & { departmentUserList: Array<(
    { __typename?: 'DepartmentUserList' }
    & FullDepartmentUserListFragment
  )> }
);

export type DepartmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DepartmentQuery = (
  { __typename?: 'Query' }
  & { department: (
    { __typename?: 'Department' }
    & FullDepartmentFragment
  ) }
);

export type DepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DepartmentsQuery = (
  { __typename?: 'Query' }
  & { departments: Array<(
    { __typename?: 'Department' }
    & FullDepartmentFragment
  )> }
);

export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  firstName
  lastName
  email
  id
  notes
  userTitle {
    id
    name
  }
  workPhoneNumber
  cellPhoneNumber
  deleted
}
    `;
export const FullInformationRequestFragmentDoc = gql`
    fragment FullInformationRequest on InformationRequest {
  id
  requestingUserId
  projectTypeId
  projectNumber
  requestLevelId
  modelNumber
  departmentId
  informationRequestStatusId
  title
  assignedUserId
  requestedDate
  createdAt
  closedDate
  informationRequestUserList {
    __typename
  }
  requestLevel {
    id
    name
  }
  projectType {
    id
    name
  }
  informationRequestStatus {
    id
    name
  }
  assignedUser {
    ...FullUser
  }
  department {
    id
    name
  }
  requestingUser {
    ...FullUser
  }
  informationRequestHasMessage {
    message {
      id
      userId
      user {
        ...FullUser
      }
      createdAt
      body
    }
  }
}
    ${FullUserFragmentDoc}`;
export const FullDepartmentUserListFragmentDoc = gql`
    fragment FullDepartmentUserList on DepartmentUserList {
  departmentsId
  usersId
  user {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export const FullDepartmentFragmentDoc = gql`
    fragment FullDepartment on Department {
  id
  name
  departmentUserList {
    ...FullDepartmentUserList
  }
}
    ${FullDepartmentUserListFragmentDoc}`;
export const CreateDeliveryTypeDocument = gql`
    mutation createDeliveryType($createDeliveryType: DeliveryTypeCreateInput!) {
  createDeliveryType(deliveryType: $createDeliveryType) {
    id
    name
    isShipping
  }
}
    `;
export type CreateDeliveryTypeMutationFn = ApolloReactCommon.MutationFunction<CreateDeliveryTypeMutation, CreateDeliveryTypeMutationVariables>;
export type CreateDeliveryTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDeliveryTypeMutation, CreateDeliveryTypeMutationVariables>, 'mutation'>;

    export const CreateDeliveryTypeComponent = (props: CreateDeliveryTypeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDeliveryTypeMutation, CreateDeliveryTypeMutationVariables> mutation={CreateDeliveryTypeDocument} {...props} />
    );
    
export type CreateDeliveryTypeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateDeliveryTypeMutation, CreateDeliveryTypeMutationVariables>
    } & TChildProps;
export function withCreateDeliveryType<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateDeliveryTypeMutation,
  CreateDeliveryTypeMutationVariables,
  CreateDeliveryTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateDeliveryTypeMutation, CreateDeliveryTypeMutationVariables, CreateDeliveryTypeProps<TChildProps, TDataName>>(CreateDeliveryTypeDocument, {
      alias: 'createDeliveryType',
      ...operationOptions
    });
};

/**
 * __useCreateDeliveryTypeMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryTypeMutation, { data, loading, error }] = useCreateDeliveryTypeMutation({
 *   variables: {
 *      createDeliveryType: // value for 'createDeliveryType'
 *   },
 * });
 */
export function useCreateDeliveryTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeliveryTypeMutation, CreateDeliveryTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDeliveryTypeMutation, CreateDeliveryTypeMutationVariables>(CreateDeliveryTypeDocument, baseOptions);
      }
export type CreateDeliveryTypeMutationHookResult = ReturnType<typeof useCreateDeliveryTypeMutation>;
export type CreateDeliveryTypeMutationResult = ApolloReactCommon.MutationResult<CreateDeliveryTypeMutation>;
export type CreateDeliveryTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDeliveryTypeMutation, CreateDeliveryTypeMutationVariables>;
export const UpdateDeliveryTypeDocument = gql`
    mutation updateDeliveryType($updateDeliveryType: DeliveryTypeUpdateInput!) {
  updateDeliveryType(deliveryType: $updateDeliveryType) {
    id
    name
    isShipping
  }
}
    `;
export type UpdateDeliveryTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateDeliveryTypeMutation, UpdateDeliveryTypeMutationVariables>;
export type UpdateDeliveryTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDeliveryTypeMutation, UpdateDeliveryTypeMutationVariables>, 'mutation'>;

    export const UpdateDeliveryTypeComponent = (props: UpdateDeliveryTypeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDeliveryTypeMutation, UpdateDeliveryTypeMutationVariables> mutation={UpdateDeliveryTypeDocument} {...props} />
    );
    
export type UpdateDeliveryTypeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDeliveryTypeMutation, UpdateDeliveryTypeMutationVariables>
    } & TChildProps;
export function withUpdateDeliveryType<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDeliveryTypeMutation,
  UpdateDeliveryTypeMutationVariables,
  UpdateDeliveryTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDeliveryTypeMutation, UpdateDeliveryTypeMutationVariables, UpdateDeliveryTypeProps<TChildProps, TDataName>>(UpdateDeliveryTypeDocument, {
      alias: 'updateDeliveryType',
      ...operationOptions
    });
};

/**
 * __useUpdateDeliveryTypeMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryTypeMutation, { data, loading, error }] = useUpdateDeliveryTypeMutation({
 *   variables: {
 *      updateDeliveryType: // value for 'updateDeliveryType'
 *   },
 * });
 */
export function useUpdateDeliveryTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeliveryTypeMutation, UpdateDeliveryTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDeliveryTypeMutation, UpdateDeliveryTypeMutationVariables>(UpdateDeliveryTypeDocument, baseOptions);
      }
export type UpdateDeliveryTypeMutationHookResult = ReturnType<typeof useUpdateDeliveryTypeMutation>;
export type UpdateDeliveryTypeMutationResult = ApolloReactCommon.MutationResult<UpdateDeliveryTypeMutation>;
export type UpdateDeliveryTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeliveryTypeMutation, UpdateDeliveryTypeMutationVariables>;
export const AddDepartmentUserDocument = gql`
    mutation addDepartmentUser($departmentUser: DepartmentUserInput!) {
  addDepartmentUser(departmentUser: $departmentUser) {
    ...FullDepartmentUserList
  }
}
    ${FullDepartmentUserListFragmentDoc}`;
export type AddDepartmentUserMutationFn = ApolloReactCommon.MutationFunction<AddDepartmentUserMutation, AddDepartmentUserMutationVariables>;
export type AddDepartmentUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddDepartmentUserMutation, AddDepartmentUserMutationVariables>, 'mutation'>;

    export const AddDepartmentUserComponent = (props: AddDepartmentUserComponentProps) => (
      <ApolloReactComponents.Mutation<AddDepartmentUserMutation, AddDepartmentUserMutationVariables> mutation={AddDepartmentUserDocument} {...props} />
    );
    
export type AddDepartmentUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddDepartmentUserMutation, AddDepartmentUserMutationVariables>
    } & TChildProps;
export function withAddDepartmentUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddDepartmentUserMutation,
  AddDepartmentUserMutationVariables,
  AddDepartmentUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddDepartmentUserMutation, AddDepartmentUserMutationVariables, AddDepartmentUserProps<TChildProps, TDataName>>(AddDepartmentUserDocument, {
      alias: 'addDepartmentUser',
      ...operationOptions
    });
};

/**
 * __useAddDepartmentUserMutation__
 *
 * To run a mutation, you first call `useAddDepartmentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDepartmentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDepartmentUserMutation, { data, loading, error }] = useAddDepartmentUserMutation({
 *   variables: {
 *      departmentUser: // value for 'departmentUser'
 *   },
 * });
 */
export function useAddDepartmentUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDepartmentUserMutation, AddDepartmentUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDepartmentUserMutation, AddDepartmentUserMutationVariables>(AddDepartmentUserDocument, baseOptions);
      }
export type AddDepartmentUserMutationHookResult = ReturnType<typeof useAddDepartmentUserMutation>;
export type AddDepartmentUserMutationResult = ApolloReactCommon.MutationResult<AddDepartmentUserMutation>;
export type AddDepartmentUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDepartmentUserMutation, AddDepartmentUserMutationVariables>;
export const RemoveDepartmentUserDocument = gql`
    mutation removeDepartmentUser($departmentUser: DepartmentUserInput!) {
  removeDepartmentUser(departmentUser: $departmentUser)
}
    `;
export type RemoveDepartmentUserMutationFn = ApolloReactCommon.MutationFunction<RemoveDepartmentUserMutation, RemoveDepartmentUserMutationVariables>;
export type RemoveDepartmentUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveDepartmentUserMutation, RemoveDepartmentUserMutationVariables>, 'mutation'>;

    export const RemoveDepartmentUserComponent = (props: RemoveDepartmentUserComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveDepartmentUserMutation, RemoveDepartmentUserMutationVariables> mutation={RemoveDepartmentUserDocument} {...props} />
    );
    
export type RemoveDepartmentUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveDepartmentUserMutation, RemoveDepartmentUserMutationVariables>
    } & TChildProps;
export function withRemoveDepartmentUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveDepartmentUserMutation,
  RemoveDepartmentUserMutationVariables,
  RemoveDepartmentUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveDepartmentUserMutation, RemoveDepartmentUserMutationVariables, RemoveDepartmentUserProps<TChildProps, TDataName>>(RemoveDepartmentUserDocument, {
      alias: 'removeDepartmentUser',
      ...operationOptions
    });
};

/**
 * __useRemoveDepartmentUserMutation__
 *
 * To run a mutation, you first call `useRemoveDepartmentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDepartmentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDepartmentUserMutation, { data, loading, error }] = useRemoveDepartmentUserMutation({
 *   variables: {
 *      departmentUser: // value for 'departmentUser'
 *   },
 * });
 */
export function useRemoveDepartmentUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDepartmentUserMutation, RemoveDepartmentUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveDepartmentUserMutation, RemoveDepartmentUserMutationVariables>(RemoveDepartmentUserDocument, baseOptions);
      }
export type RemoveDepartmentUserMutationHookResult = ReturnType<typeof useRemoveDepartmentUserMutation>;
export type RemoveDepartmentUserMutationResult = ApolloReactCommon.MutationResult<RemoveDepartmentUserMutation>;
export type RemoveDepartmentUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDepartmentUserMutation, RemoveDepartmentUserMutationVariables>;
export const DeliveryTypeQueryParamDocument = gql`
    query DeliveryTypeQueryParam($id: ID!) {
  deliveryType(id: $id) {
    id
    name
    isShipping
  }
}
    `;
export type DeliveryTypeQueryParamComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables>, 'query'> & ({ variables: DeliveryTypeQueryParamQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DeliveryTypeQueryParamComponent = (props: DeliveryTypeQueryParamComponentProps) => (
      <ApolloReactComponents.Query<DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables> query={DeliveryTypeQueryParamDocument} {...props} />
    );
    
export type DeliveryTypeQueryParamProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables>
    } & TChildProps;
export function withDeliveryTypeQueryParam<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeliveryTypeQueryParamQuery,
  DeliveryTypeQueryParamQueryVariables,
  DeliveryTypeQueryParamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables, DeliveryTypeQueryParamProps<TChildProps, TDataName>>(DeliveryTypeQueryParamDocument, {
      alias: 'deliveryTypeQueryParam',
      ...operationOptions
    });
};

/**
 * __useDeliveryTypeQueryParamQuery__
 *
 * To run a query within a React component, call `useDeliveryTypeQueryParamQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryTypeQueryParamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryTypeQueryParamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeliveryTypeQueryParamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables>(DeliveryTypeQueryParamDocument, baseOptions);
      }
export function useDeliveryTypeQueryParamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables>(DeliveryTypeQueryParamDocument, baseOptions);
        }
export type DeliveryTypeQueryParamQueryHookResult = ReturnType<typeof useDeliveryTypeQueryParamQuery>;
export type DeliveryTypeQueryParamLazyQueryHookResult = ReturnType<typeof useDeliveryTypeQueryParamLazyQuery>;
export type DeliveryTypeQueryParamQueryResult = ApolloReactCommon.QueryResult<DeliveryTypeQueryParamQuery, DeliveryTypeQueryParamQueryVariables>;
export const DeliveryTypeQueryDocument = gql`
    query DeliveryTypeQuery {
  deliveryTypes {
    id
    name
    isShipping
  }
}
    `;
export type DeliveryTypeQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables>, 'query'>;

    export const DeliveryTypeQueryComponent = (props: DeliveryTypeQueryComponentProps) => (
      <ApolloReactComponents.Query<DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables> query={DeliveryTypeQueryDocument} {...props} />
    );
    
export type DeliveryTypeQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables>
    } & TChildProps;
export function withDeliveryTypeQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeliveryTypeQueryQuery,
  DeliveryTypeQueryQueryVariables,
  DeliveryTypeQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables, DeliveryTypeQueryProps<TChildProps, TDataName>>(DeliveryTypeQueryDocument, {
      alias: 'deliveryTypeQuery',
      ...operationOptions
    });
};

/**
 * __useDeliveryTypeQueryQuery__
 *
 * To run a query within a React component, call `useDeliveryTypeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryTypeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryTypeQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeliveryTypeQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables>(DeliveryTypeQueryDocument, baseOptions);
      }
export function useDeliveryTypeQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables>(DeliveryTypeQueryDocument, baseOptions);
        }
export type DeliveryTypeQueryQueryHookResult = ReturnType<typeof useDeliveryTypeQueryQuery>;
export type DeliveryTypeQueryLazyQueryHookResult = ReturnType<typeof useDeliveryTypeQueryLazyQuery>;
export type DeliveryTypeQueryQueryResult = ApolloReactCommon.QueryResult<DeliveryTypeQueryQuery, DeliveryTypeQueryQueryVariables>;
export const InformationRequestByIdDocument = gql`
    query InformationRequestById($id: ID!) {
  informationRequest(id: $id) {
    ...FullInformationRequest
  }
}
    ${FullInformationRequestFragmentDoc}`;
export type InformationRequestByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InformationRequestByIdQuery, InformationRequestByIdQueryVariables>, 'query'> & ({ variables: InformationRequestByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InformationRequestByIdComponent = (props: InformationRequestByIdComponentProps) => (
      <ApolloReactComponents.Query<InformationRequestByIdQuery, InformationRequestByIdQueryVariables> query={InformationRequestByIdDocument} {...props} />
    );
    
export type InformationRequestByIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InformationRequestByIdQuery, InformationRequestByIdQueryVariables>
    } & TChildProps;
export function withInformationRequestById<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InformationRequestByIdQuery,
  InformationRequestByIdQueryVariables,
  InformationRequestByIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InformationRequestByIdQuery, InformationRequestByIdQueryVariables, InformationRequestByIdProps<TChildProps, TDataName>>(InformationRequestByIdDocument, {
      alias: 'informationRequestById',
      ...operationOptions
    });
};

/**
 * __useInformationRequestByIdQuery__
 *
 * To run a query within a React component, call `useInformationRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useInformationRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInformationRequestByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInformationRequestByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InformationRequestByIdQuery, InformationRequestByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<InformationRequestByIdQuery, InformationRequestByIdQueryVariables>(InformationRequestByIdDocument, baseOptions);
      }
export function useInformationRequestByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InformationRequestByIdQuery, InformationRequestByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InformationRequestByIdQuery, InformationRequestByIdQueryVariables>(InformationRequestByIdDocument, baseOptions);
        }
export type InformationRequestByIdQueryHookResult = ReturnType<typeof useInformationRequestByIdQuery>;
export type InformationRequestByIdLazyQueryHookResult = ReturnType<typeof useInformationRequestByIdLazyQuery>;
export type InformationRequestByIdQueryResult = ApolloReactCommon.QueryResult<InformationRequestByIdQuery, InformationRequestByIdQueryVariables>;
export const AllInformationRequestsDocument = gql`
    query AllInformationRequests {
  informationRequests {
    ...FullInformationRequest
  }
}
    ${FullInformationRequestFragmentDoc}`;
export type AllInformationRequestsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllInformationRequestsQuery, AllInformationRequestsQueryVariables>, 'query'>;

    export const AllInformationRequestsComponent = (props: AllInformationRequestsComponentProps) => (
      <ApolloReactComponents.Query<AllInformationRequestsQuery, AllInformationRequestsQueryVariables> query={AllInformationRequestsDocument} {...props} />
    );
    
export type AllInformationRequestsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllInformationRequestsQuery, AllInformationRequestsQueryVariables>
    } & TChildProps;
export function withAllInformationRequests<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllInformationRequestsQuery,
  AllInformationRequestsQueryVariables,
  AllInformationRequestsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllInformationRequestsQuery, AllInformationRequestsQueryVariables, AllInformationRequestsProps<TChildProps, TDataName>>(AllInformationRequestsDocument, {
      alias: 'allInformationRequests',
      ...operationOptions
    });
};

/**
 * __useAllInformationRequestsQuery__
 *
 * To run a query within a React component, call `useAllInformationRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInformationRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInformationRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllInformationRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllInformationRequestsQuery, AllInformationRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllInformationRequestsQuery, AllInformationRequestsQueryVariables>(AllInformationRequestsDocument, baseOptions);
      }
export function useAllInformationRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllInformationRequestsQuery, AllInformationRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllInformationRequestsQuery, AllInformationRequestsQueryVariables>(AllInformationRequestsDocument, baseOptions);
        }
export type AllInformationRequestsQueryHookResult = ReturnType<typeof useAllInformationRequestsQuery>;
export type AllInformationRequestsLazyQueryHookResult = ReturnType<typeof useAllInformationRequestsLazyQuery>;
export type AllInformationRequestsQueryResult = ApolloReactCommon.QueryResult<AllInformationRequestsQuery, AllInformationRequestsQueryVariables>;
export const DepartmentDocument = gql`
    query department($id: ID!) {
  department(id: $id) {
    ...FullDepartment
  }
}
    ${FullDepartmentFragmentDoc}`;
export type DepartmentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DepartmentQuery, DepartmentQueryVariables>, 'query'> & ({ variables: DepartmentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DepartmentComponent = (props: DepartmentComponentProps) => (
      <ApolloReactComponents.Query<DepartmentQuery, DepartmentQueryVariables> query={DepartmentDocument} {...props} />
    );
    
export type DepartmentProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DepartmentQuery, DepartmentQueryVariables>
    } & TChildProps;
export function withDepartment<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DepartmentQuery,
  DepartmentQueryVariables,
  DepartmentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DepartmentQuery, DepartmentQueryVariables, DepartmentProps<TChildProps, TDataName>>(DepartmentDocument, {
      alias: 'department',
      ...operationOptions
    });
};

/**
 * __useDepartmentQuery__
 *
 * To run a query within a React component, call `useDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepartmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentQuery, DepartmentQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentQuery, DepartmentQueryVariables>(DepartmentDocument, baseOptions);
      }
export function useDepartmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentQuery, DepartmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentQuery, DepartmentQueryVariables>(DepartmentDocument, baseOptions);
        }
export type DepartmentQueryHookResult = ReturnType<typeof useDepartmentQuery>;
export type DepartmentLazyQueryHookResult = ReturnType<typeof useDepartmentLazyQuery>;
export type DepartmentQueryResult = ApolloReactCommon.QueryResult<DepartmentQuery, DepartmentQueryVariables>;
export const DepartmentsDocument = gql`
    query departments {
  departments {
    ...FullDepartment
  }
}
    ${FullDepartmentFragmentDoc}`;
export type DepartmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DepartmentsQuery, DepartmentsQueryVariables>, 'query'>;

    export const DepartmentsComponent = (props: DepartmentsComponentProps) => (
      <ApolloReactComponents.Query<DepartmentsQuery, DepartmentsQueryVariables> query={DepartmentsDocument} {...props} />
    );
    
export type DepartmentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DepartmentsQuery, DepartmentsQueryVariables>
    } & TChildProps;
export function withDepartments<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DepartmentsQuery,
  DepartmentsQueryVariables,
  DepartmentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DepartmentsQuery, DepartmentsQueryVariables, DepartmentsProps<TChildProps, TDataName>>(DepartmentsDocument, {
      alias: 'departments',
      ...operationOptions
    });
};

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepartmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, baseOptions);
      }
export function useDepartmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, baseOptions);
        }
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>;
export type DepartmentsQueryResult = ApolloReactCommon.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>;