import { Select, ItemRenderer } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
interface ReasonArray {
  reasons: string[];
}

const ReasonSelectType = Select.ofType<string>();

const itemRenderer: ItemRenderer<string> = (
  name,
  { handleClick, index }
) => (
  <MenuItem
    key={name}
    onClick={handleClick}
    tabIndex={index}
    style={{
      fontSize: 16,
      fontWeight: 'bolder',
      margin: 2,
      textAlign: 'center'
    }}
    text={name}
  />
);
interface MyProps {
  selectedText: string | null;
  onItemSelect: (
    item: string,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void;
}

const ReasonSelect = (props: MyProps) => {
  let text = props.selectedText || 'NONE';
  return (
    <ReasonSelectType
      items={["ECO", "Missing", "Damaged", "Other"]}
      itemRenderer={itemRenderer}
      onItemSelect={props.onItemSelect}
      noResults={<MenuItem disabled={true} text="No results." />}
      className="bp3-fill"
    >
      <Button
        text={text}
        rightIcon="double-caret-vertical"
        className="bp3-fill"
      />
    </ReasonSelectType>
  );
};

export default ReasonSelect;
