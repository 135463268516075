import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { SurveyTemplate, SurveyResponse } from '../../models/models';


export interface ListSurveyTemplatesResponse {
  surveyTemplates: SurveyTemplate[];
}

export const LIST_SURVEY_TEMPLATES_QUERY = gql`
  query ListSurveyTemplates {
    surveyTemplates{
        id
        name
    }
  }
`;

export const ListSurveyTemplatesQuery= (
) =>
  useQuery<ListSurveyTemplatesResponse>(
    LIST_SURVEY_TEMPLATES_QUERY
  );

export interface GetSurveyTemplateResponse {
  surveyTemplate: SurveyTemplate;
}
export interface GetSurveyTemplateArgs {
  id: string;
}
export const GET_SURVEY_TEMPLATE = gql`
  query GetSurveyTemplate($id: ID!) {
    surveyTemplate(id: $id){
      createdAt
      id
      name
      surveySections{
        fieldNumber
        id
        title
        surveyFields{
          surveyFieldId
          surveyField{
            id
            title
            surveyDataType{
              id
              name
            }
            surveyDataTypeId
          }
          id
          fieldNumber
        }
      }
    }
  }
`;

export const GetSurveyTemplate = (args: GetSurveyTemplateArgs) => 
  useQuery<GetSurveyTemplateResponse, GetSurveyTemplateArgs>(GET_SURVEY_TEMPLATE, { variables: args });


  export interface SurveyResponseUriArgs {
    uri: string;
  }
  
export interface SurveyResponseUriResponse {
  surveyResponseUri?: SurveyResponse;
}
export const SURVEY_RESPONSE_URI_MUTATION = gql`
  query SurveyResponseUriQuery($uri: String!) {
    surveyResponseUri(uri: $uri) {
      id
      uri
      surveyTemplate {
        createdAt
        id
        name
        surveySections{
          fieldNumber
          id
          title
          surveyFields{
            surveyFieldId
            surveyField{
              id
              title
              surveyDataType{
                id
                name
              }
              surveyDataTypeId
            }
            id
            fieldNumber
          }
        }
      }
      surveyTemplateId
    }
  }
`;

export const SurveyResponseUriQuery = (args: SurveyResponseUriArgs) => 
useQuery<SurveyResponseUriResponse, SurveyResponseUriArgs>(SURVEY_RESPONSE_URI_MUTATION, { variables: args });


export interface SurveyResponsesResponse {
surveyResponses?: SurveyResponse[];
}
export const SURVEY_RESPONSES_MUTATION = gql`
query SurveyResponsesQuery {
  surveyResponses {
    id
    uri
    hiddenNotes
    companyName
    title
    hasResponded
    createdAt
    respondedDate
    createdBy{
        id
        firstName
        lastName
        email
      }
    surveyResponseHasSurveyField {
      id
      data
      surveyFieldHasSurveySectionId
    }
    surveyTemplate {
      createdAt
      id
      name
      surveySections{
        fieldNumber
        id
        title
        surveyFields{
          surveyFieldId
          surveyField{
            id
            title
            surveyDataType{
              id
              name
            }
            surveyDataTypeId
          }
          id
          fieldNumber
        }
      }
    }
    surveyTemplateId
  }
}
`;

export const SurveyResponsesQuery = () => 
useQuery<SurveyResponsesResponse>(SURVEY_RESPONSES_MUTATION);


export interface SurveyResponseArgs {
  id: string;
  }

export interface SurveyResponseResponse {
  surveyResponse?: SurveyResponse;
  }
  export const SURVEY_RESPONSE_MUTATION = gql`
  query SurveyResponseQuery($id: ID!) {
    surveyResponse(id: $id) {
      id
      uri
      hiddenNotes
      companyName
      title
      hasResponded
      createdAt
      respondedDate
      createdBy{
        id
        firstName
        lastName
        email
      }
      surveyResponseHasSurveyField {
        id
        data
        surveyFieldHasSurveySectionId
      }
      surveyTemplate {
        createdAt
        id
        name
        surveySections{
          fieldNumber
          id
          title
          surveyFields{
            surveyFieldId
            surveyField{
              id
              title
              surveyDataType{
                id
                name
              }
              surveyDataTypeId
            }
            id
            fieldNumber
          }
        }
      }
      surveyTemplateId
    }
  }
  `;
  
  export const SurveyResponseQuery = (variables: SurveyResponseArgs) => 
  useQuery<SurveyResponseResponse, SurveyResponseArgs>(SURVEY_RESPONSE_MUTATION, {variables});
  