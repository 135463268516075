import { Button } from '@blueprintjs/core';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';

import * as React from 'react';
import NotesField from '../Form/Field/NotesField';
import {
  AddMessageToInformationRequestMutation,
  AddMessageToInformationRequestArgs
} from '../../graphql/mutation/Message';
import Loading from '../Loading';

interface MyProps {
  informationRequestId: number;
}
const CreateMessage = (props: MyProps) => {
  const initialValues: AddMessageToInformationRequestArgs = {
    informationRequestId: props.informationRequestId,
    message: {
      body: ''
    }
  };

  const [
    addMessageToInformationRequest,
    { loading }
  ] = AddMessageToInformationRequestMutation(
    props.informationRequestId.toString()
  );

  const submitForm = (
    variables: AddMessageToInformationRequestArgs,
    { setSubmitting }: FormikHelpers<AddMessageToInformationRequestArgs>
  ) => {
    setSubmitting(true);
    addMessageToInformationRequest({ variables }).then(values => {
      if (values.errors) {
        console.log(values.errors);
        return;
      }
      setSubmitting(false);
    });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      render={RenderForm}
    />
  );
};

const RenderForm = (
  formikBag: FormikProps<AddMessageToInformationRequestArgs>
) => (
  <Form style={{ flex: 1 }}>
    <NotesField name="message.body" label="Message Reply" />
    <Button type="submit" intent="success" large={true}>
      Add Reply
    </Button>
  </Form>
);

export default CreateMessage;
