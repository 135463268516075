import * as React from 'react';
import { Message } from '../../models/models';
import MessageView from './MessageView';

interface MyProps {
  messages: Message[];
}

const ListMessageViews = (props: MyProps) => {
  const list = props.messages.map(m => <MessageView key={m.id} message={m} />);

  return <div>{list}</div>;
};

export default ListMessageViews;
