import gql from 'graphql-tag';
import { InformationRequestStatus } from '../../models/models';
import { useQuery } from '@apollo/react-hooks';

interface InformationRequestStatusesQuery {
  informationRequestStatuses: InformationRequestStatus[];
}

export const INFORMATION_REQUEST_STATUSES_QUERY = gql`
  query InformationRequestStatuses {
    informationRequestStatuses {
      id
      name
    }
  }
`;

export const InformationRequestStatueses = () =>
  useQuery<InformationRequestStatusesQuery>(INFORMATION_REQUEST_STATUSES_QUERY);
