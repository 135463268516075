import * as React from 'react';

const Home = () => (
  <div>
    <h1>Welcome!</h1>
    <p>This is the future webpage of Shield air production application</p>
  </div>
);

export default Home;
