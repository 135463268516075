import * as React from "react";
import { SurveyTemplate } from "../models/models";
import { H3, Card } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const SurveyTemplateCard = (props: { surveyTemplate: SurveyTemplate}) => {
    const {surveyTemplate} = props;
    return (
        <Card>
            <Link to={"/view/survey/"+surveyTemplate.id}>
                <H3>{surveyTemplate.name}</H3>
            </Link>
        </Card>);
}
export default SurveyTemplateCard;