import gql from 'graphql-tag';
import { FULL_USER_FRAGMENT } from '../Fragment/User';

export const PART_TRACKER_QUERY = gql`
  query PartTrackers {
    partTrackers {
      id
      jobNumber
      lineNumber
      partName
      recNumber
      poNumber
      poDate
      poRecievedDate
      assemblyTeam {
        id
        name
      }
      sasPartNumber
      manPartNumber
      quantity
      vendor
      createdDate
      expectedDate
      ecoNumber
      department {
        id
        name
      }
      status {
        id
        name
      }
      deliveryType {
        id
        name
        isShipping
      }
      deliveryNotes
      reason
      reasonNotes
      trackingNumber
      partsTrackerStatusId
      assemblyTeamId
      deliveryTypeId
      departmentId
      deliveryDate
      projectManagerId
      projectManager {
        ...FullUser
      }
      designRepId
      designRep {
        ...FullUser
      }
      lastModifiedByUser {
        ...FullUser
      }
      createdByUser {
        ...FullUser
      }
      ecoNotes
      requestedDate
    }
  }
  ${FULL_USER_FRAGMENT}
`;

export const PART_TRACKER_ID_QUERY = gql`
  query PartTracker($id: ID!) {
    partTracker(id: $id) {
      id
      jobNumber
      lineNumber
      partName
      recNumber
      poNumber
      poDate
      poRecievedDate
      assemblyTeam {
        id
        name
      }
      sasPartNumber
      manPartNumber
      quantity
      vendor
      createdDate
      expectedDate
      ecoNumber
      department {
        id
        name
      }
      status {
        id
        name
      }
      deliveryType {
        id
        name
        isShipping
      }
      deliveryNotes
      reason
      reasonNotes
      trackingNumber
      partsTrackerStatusId
      assemblyTeamId
      deliveryTypeId
      departmentId
      deliveryDate
      projectManagerId
      projectManager {
        ...FullUser
      }
      designRepId
      designRep {
        ...FullUser
      }
      lastModifiedByUser {
        ...FullUser
      }
      createdByUser {
        ...FullUser
      }
      ecoNotes
      requestedDate
    }
  }
  ${FULL_USER_FRAGMENT}
`;

export const PART_TRACKER_STATUSES = gql`
  query PartTrackerStatuses {
    partTrackerStatuses {
      id
      name
    }
  }
`;

export const DELIVERY_TYPES_QUERY = gql`
  query DeliveryTypeQuery {
    deliveryTypes {
      id
      name
      isShipping
    }
  }
`;
export interface DeliveryTypeQueryArgs {
  id: string;
}
export const DELIVERY_TYPE_QUERY = gql`
  query DeliveryTypeQueryParam($id: ID!) {
    deliveryType(id: $id) {
      id
      name
      isShipping
    }
  }
`;
