import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import RequireUser from './User/RequireUser';

export interface RequireLoginRouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    path: string | string[];
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
}
export class RequireLoginRoute<T extends RequireLoginRouteProps = RequireLoginRouteProps> extends React.Component<T, any> {
    render() {
        let { component } = this.props;
        return (
            <Route path={this.props.path} exact={this.props.exact} sensitive={this.props.sensitive} strict={this.props.strict} render={(props) => (
                <RequireUser>
                    {React.createElement(component, props)}
                </RequireUser>
            )} />

        );
    }

}