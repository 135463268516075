import * as React from 'react';
import { Card, Elevation } from '@blueprintjs/core';

const ResetLinkSuccess = () => (
  <Card elevation={Elevation.TWO} style={{ minWidth: '25%' }}>
    <h2>Password has been reset!</h2>
    <p>Next step is to login.</p>
  </Card>
);
export default ResetLinkSuccess;
