import MyDatePicker from '../MyDatePicker';
import * as React from 'react';
import { FieldProps, Field } from 'formik';
import { FormGroup } from '@blueprintjs/core';
import moment from 'moment';

const DatePicker = ({ field, form }: FieldProps) => {
  if (field.value) {
    field.value = moment(field.value).toDate();
  }
  return (
    <MyDatePicker
      value={field.value}
      name={field.name}
      onChange={field.onChange}
    />
  );
};
interface MyProps {
  label: string;
  name: string;
}
const DateField = (props: MyProps) => (
  <FormGroup label={props.label}>
    <Field name={props.name} render={DatePicker} />
  </FormGroup>
);

export default DateField;
