import { ColGroupDef } from 'ag-grid-community';
import { dateCol, strCol, intCol } from './ColDef';
import { genUserCols } from './ColGroupDef';

export const getInformationRequest = (str: string): ColGroupDef => {
  return {
    headerName: 'InformationRequest',
    children: [
      {
        headerName: 'id',
        cellRenderer: params => {
          if (!params.value) {
            return '';
          }
          return `<a href="/InformationRequest/view/${params.value}"  rel="noopener" target="_blank"}>${params.value}</a>`;
        },
        field: str + 'id',
        ...intCol
      },
      {
        headerName: 'Status',
        field: str + 'informationRequestStatus.name',
        filterParams: 'Closed',
        ...strCol
      },
      {
        headerName: 'Request Level',
        field: str + 'requestLevel.name',
        ...strCol
      },
      {
        headerName: 'Requesting User',
        children: genUserCols(str + 'requestingUser')
      },
      {
        headerName: 'Assigned User',
        children: genUserCols(str + 'assignedUser')
      },
      {
        headerName: 'Model Number',
        field: str + 'modelNumber',
        ...strCol
      },
      {
        headerName: 'Project Number',
        field: str + 'projectNumber',
        ...strCol
      },
      {
        headerName: 'Title',
        field: str + 'title',
        ...strCol
      },
      {
        headerName: 'Department',
        field: str + 'department.name',
        ...strCol
      },

      {
        headerName: 'Project Type',
        field: str + 'projectType.name',
        ...strCol
      },

      {
        headerName: 'Created At',
        field: str + 'createdAt',
        ...dateCol
      },
      {
        headerName: 'Requested Date',
        field: str + 'requestedDate',
        ...dateCol
      },
      {
        headerName: 'Closed Date',
        field: str + 'closedDate',
        ...dateCol
      }
    ]
  };
};
