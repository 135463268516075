import { Select, ItemRenderer } from '@blueprintjs/select';
import { Department } from '../../../models/models';
import { useQuery } from '@apollo/react-hooks';
import { Button, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
import { DEPARTMENTS_QUERY } from '../../../graphql/query/Department';
interface DepartmentArray {
  departments: Department[];
}

const DepartmentSelectType = Select.ofType<Department>();

const itemRenderer: ItemRenderer<Department> = (
  { id, name },
  { handleClick, index }
) => (
  <MenuItem
    key={id}
    onClick={handleClick}
    tabIndex={index}
    style={{
      fontSize: 16,
      fontWeight: 'bolder',
      margin: 2,
      textAlign: 'center'
    }}
    text={name}
  />
);
interface MyProps {
  selectedId: number;
  onItemSelect: (
    item: Department,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void;
}

const DepartmentSelect = (props: MyProps) => {
  const { loading, error, data } = useQuery<DepartmentArray>(DEPARTMENTS_QUERY);
  if (loading) {
    return <p>loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  if (data == null || data.departments == null) {
    return <p>is null</p>;
  }
  const find = data.departments.find(a => a.id === props.selectedId);
  let text = 'NONE';
  if (find) {
    text = find.name;
  }
  return (
    <DepartmentSelectType
      items={data.departments}
      itemRenderer={itemRenderer}
      onItemSelect={props.onItemSelect}
      noResults={<MenuItem disabled={true} text="No results." />}
      className="bp3-fill"
    >
      <Button
        text={text}
        rightIcon="double-caret-vertical"
        className="bp3-fill"
      />
    </DepartmentSelectType>
  );
};

export default DepartmentSelect;
