import * as React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { ColGroupDef } from 'ag-grid-community';

import 'ag-grid-enterprise';
import { standardSideBar, genDepartments } from '../../Table/ColGroupDef';
import Loading from '../Loading';
import ErrorComponent from '../ErrorComponent';
import { useDepartmentsQuery } from '../../generated/graphql';

function ViewDepartmentsTable() {
  const colGroup: ColGroupDef[] = [genDepartments('')];

  const { loading, error, data } = useDepartmentsQuery();
  if (loading) {
    return <Loading />;
  }
  if (!data) {
    return <ErrorComponent />;
  }
  if (error) {
    return <ErrorComponent errorText={error.message} />;
  }

  return (
    <div style={{ height: 'calc(100vh - 50px)' }} className="ag-theme-balham">
      <AgGridReact
        columnDefs={colGroup}
        enableRangeSelection={true}
        sideBar={standardSideBar}
        enableCharts={true}
        rowData={data.departments}
      ></AgGridReact>
    </div>
  );
}

export default ViewDepartmentsTable;
