import { FormGroup, Button, Card } from "@blueprintjs/core";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import Loading from "../Loading";
import ErrorComponent from "../ErrorComponent";
import {
  useAddDepartmentUserMutation,
  DepartmentQueryVariables,
  useDepartmentQuery,
  DepartmentDocument,
  DepartmentQuery,
  FullDepartmentUserListFragment
} from "../../generated/graphql";
import {
  standardSideBar,
  genDepartmentUserList
} from "../../Table/ColGroupDef";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { ColGroupDef } from "ag-grid-community";
import UserSelect2 from "../Form/Select/User/UserSelect";
const UpdateDepartmentForm = (props: RouteComponentProps<{ id: string }>) => {
  const { id } = props.match.params;
  const { loading: loading2, error, data } = useDepartmentQuery({
    variables: { id }
  });

  const [addDepartmentUser, { loading }] = useAddDepartmentUserMutation({ update(cache, { data }) {
    const out = cache.readQuery<DepartmentQuery, DepartmentQueryVariables>({ query: DepartmentDocument, variables: { id }})
    if (!data?.addDepartmentUser) {
      return;
    }
    if (!out?.department?.departmentUserList){
      return;
    }
    cache.writeQuery<DepartmentQuery, DepartmentQueryVariables>({ query: DepartmentDocument, variables: { id }, data: {department: { ...out.department, departmentUserList: out?.department.departmentUserList.concat([data.addDepartmentUser])}}});
  } });
  const [selectedUser, setSelectedUser] = React.useState<number>();
  const addUser = () =>{
    if (!selectedUser) {
      return;
    }

    addDepartmentUser({variables: {departmentUser: {departmentsId: Number.parseInt(id), usersId: selectedUser}}});
  }
  if (loading2 || loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorComponent />;
  }
  if (!data?.department) {
    return <ErrorComponent />;
  }
  const colGroup: ColGroupDef[] = [genDepartmentUserList("")];
  return (
    <div style={{ height: "calc(100vh - 50px)" }}>
      <h2>Department</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          height: "100%"
        }}
      >
        <div style={{ margin: 20, width: "100%", height: "100%" }}>
          <h4>{data?.department.name}</h4>
          <div
            style={{ height: "70%", width: "100%" }}
            className="ag-theme-balham"
          >
            <AgGridReact
              columnDefs={colGroup}
              enableRangeSelection={true}
              sideBar={standardSideBar}
              enableCharts={true}
              rowData={data.department.departmentUserList}
            ></AgGridReact>
            <UserSelect2 selectedId={selectedUser} onItemSelect={(item) => setSelectedUser(item.id)}></UserSelect2>
            <Button onClick={() => addUser()}>Add</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
const a = (departmentUser: FullDepartmentUserListFragment) => {
  return <Card>{departmentUser.user?.email}</Card>;
};
export default UpdateDepartmentForm;
