import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export interface RequestPasswortResetArgs {
  email: string;
}

export interface RequestPasswortResetResponse {
  requestPasswordReset?: boolean;
}
export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const RequestPasswordReset = () =>
  useMutation<RequestPasswortResetResponse, RequestPasswortResetArgs>(
    REQUEST_PASSWORD_RESET,
    { onError: () => {} }
  );

export interface ChangePasswordLinkArgs {
  email: string;
  password: string;
  secret: string;
}

export interface ChangePasswordLinkResponse {
  changePasswordLink?: boolean;
}
export const CHANGE_PASSWORD_LINK = gql`
  mutation changePasswordLink(
    $email: String!
    $password: String!
    $secret: String!
  ) {
    changePasswordLink(email: $email, newPassword: $password, secret: $secret)
  }
`;

export const ChangePasswordLink = () => {
  return useMutation<ChangePasswordLinkResponse, ChangePasswordLinkArgs>(
    CHANGE_PASSWORD_LINK,
    { onError: () => {} }
  );
};
