import gql from 'graphql-tag';

export const FULL_ACK_FRAGMENT = gql`
  fragment FullAck on Acknowledgement {
    accountingReviewDate
    acknowledgementDate
    engineeringReleaseDate
    jobNumber
    notes
    productionReviewDate
  }
`;
