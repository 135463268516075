import { ColGroupDef } from 'ag-grid-community';
import { dateCol, strCol, intCol } from './ColDef';

export const getPartTracker = (str: string): ColGroupDef => {
  return {
    headerName: 'PartTracker',
    children: [
      {
        headerName: 'id',
        cellRenderer: params => {
          if (!params.value) {
            return '';
          }
          return `<a href="/parttracker/view/${params.value}"  rel="noopener" target="_blank">${params.value}</a>`;
        },
        field: str + 'id',
        ...intCol
      },
      {
        headerName: 'Job Number',
        field: str + 'jobNumber',
        ...intCol
      },
      {
        headerName: 'Line Number',
        field: str + 'lineNumber',
        ...intCol
      },
      {
        headerName: 'Department',
        field: str + 'department.name',
        ...strCol
      },
      {
        headerName: 'Status',
        field: str + 'status.name',
        ...strCol
      },
      {
        headerName: 'PM Last Name',
        field: str + 'projectManager.lastName',
        ...strCol
      },
      {
        headerName: 'Design Rep Last Name',
        field: str + 'designRep.lastName',
        ...strCol
      },

      {
        headerName: 'Part Name',
        field: str + 'partName',
        ...strCol
      },
      {
        headerName: 'Created Date',
        field: str + 'createdDate',
        ...dateCol
      },
      {
        headerName: 'Created By Last Name',
        field: str + 'createdByUser.lastName',
        ...strCol
      },
      {
        headerName: 'Last Modified By Last Name',
        field: str + 'lastModifiedByUser.lastName',
        ...strCol
      },
      {
        headerName: 'PO #',
        field: str + 'poNumber',
        ...strCol
      },
      {
        headerName: 'PO Date',
        field: str + 'poDate',
        ...dateCol
      },
      {
        headerName: 'PO Recieved Date',
        field: str + 'poRecievedDate',
        ...dateCol
      },
      {
        headerName: 'Rec #',
        field: str + 'recNumber',
        ...strCol
      },
      {
        headerName: 'Eco #',
        field: str + 'ecoNumber',
        ...strCol
      },
      {
        headerName: 'Assembly Team',
        field: str + 'assemblyTeam.name',
        ...strCol
      },
      {
        headerName: 'Delivery Type',
        field: str + 'deliveryType.name',
        ...strCol
      },
      {
        headerName: 'SAS Part #',
        field: str + 'sasPartNumber',
        ...strCol
      },
      {
        headerName: 'Man Part #',
        field: str + 'manPartNumber',
        ...strCol
      },
      {
        headerName: 'Quantity',
        field: str + 'quantity',
        ...intCol
      },
      {
        headerName: 'Vendor',
        field: str + 'vendor',
        ...strCol
      },
      {
        headerName: 'Requested Date',
        field: str + 'requestedDate',
        ...dateCol
      },
      {
        headerName: 'Expected Date',
        field: str + 'expectedDate',
        ...dateCol
      },
      {
        headerName: 'Delivery Date',
        field: str + 'deliveryDate',
        ...dateCol
      },
      {
        headerName: 'Tracking Number',
        field: str + 'trackingNumber',
        ...strCol
      }
    ]
  };
};
