import * as React from 'react';
import { Message, Users } from '../../models/models';
import { Card, H3 } from '@blueprintjs/core';
import { formatUser } from '../Form/Select/User/UserSelectRender';
import moment from 'moment';

interface MyProps {
  message: Message;
}
const UserMessage = (props: { user: Users }) => (
  <H3>{formatUser(props.user)}</H3>
);
const MessageView = (props: MyProps) => (
  <Card>
    <UserMessage user={props.message.user} />
    <p>
      {moment(props.message.createdAt)
        .local()
        .format('LLL')}
    </p>
    <p>
      {props.message.body.split('\n').map(function(item, key) {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      })}
    </p>
  </Card>
);

export default MessageView;
