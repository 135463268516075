import gql from 'graphql-tag';

export const FULL_USER_FRAGMENT = gql`
  fragment FullUser on User {
    firstName
    lastName
    email
    id
    notes
    userTitle {
      id
      name
    }
    workPhoneNumber
    cellPhoneNumber
    deleted
  }
`;
