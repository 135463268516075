import { Select, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { Users } from '../../../../models/models';
import { MenuItem } from '@blueprintjs/core';
import * as React from 'react';

export const userItemRenderer: ItemRenderer<Users> = (
  user,
  { handleClick, index, modifiers }
) => (
  <MenuItem
    key={user.id}
    onClick={handleClick}
    tabIndex={index}
    active={modifiers.active}
    disabled={modifiers.disabled}
    style={{
      fontSize: 16,
      fontWeight: 'bolder',
      margin: 2,
      textAlign: 'center'
    }}
    text={formatUser(user)}
  />
);
export const filterUser: ItemPredicate<Users> = (
  query,
  user,
  _index,
  exactMatch
) => {
  const normalizedName = formatUser(user).toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  } else {
    return normalizedName.indexOf(normalizedQuery) >= 0;
  }
};

export const formatUser = (user: Users): string =>
  user.firstName + ' ' + user.lastName;

export interface UsersArray {
  users: Users[];
}

export const UserSelect = Select.ofType<Users>();
